@keyframes move {
  from {
    transform: translateY(0);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateY(100px);
  }
}
.roundedBox(){
  border-radius: 1.5%;
}
.scratch-win-title {
  margin: 15px 0 20px;
  text-align: center;
  text-transform: none;
}
.scratch-win-game {
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 20px;
  padding: 0 5px;
  text-align: center;
}
.scratch-win-rules {
  margin: 0 0 40px;
  padding: 0 5px;
  h2 {
    margin: 0 0 10px;
    text-align: center;
  }
  p {
    font-size: 13px;
    text-align: left;
  }
}
#scratch {
  position: relative;
  width: 100%;
  max-width: 748px;
  margin: 0 auto 20px;
  text-align: center;
  .scratch-background {
    position: relative;
    padding: 0 0 127.94715%;
    box-sizing: border-box;
  }
  .js--sc--container {
    position: absolute;
    top: 25.45%;
    left: 10.5%;
    right: 10.5%;
    bottom: 12.8%;
    user-select: none;
    opacity: 1;
    .roundedBox();
    canvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .roundedBox();
    }
    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      user-select: none;
      .roundedBox();
    }
  }
}
@media screen and (min-width: 800px){
  .scratch-win-game {
    padding: 0 10px;
  }
  .scratch-win-rules {
    padding: 0 10px;
    p {
      text-align: center;
    }
  }
  #scratch {
    width: 100%;
    max-width: none;
    .scratch-background {
      padding: 0 0 28.620689%;
    }
    .js--sc--container {
      top: 5.5%;
      left: 68.9%;
      right: 5.7%;
      bottom: 5.5%;
    }
  }
}
#win--container {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  background-color: @white;
  opacity: 0;
  z-index: 0;
  .roundedBox();
  h2 {
    margin: 0 0 15px;
    font-size: 20px;
    line-height: 25px;
    color: @pink;
  }
  .voucher-name {
    margin: 0 0 20px;
    .sans-serif-regular();
    font-size: 13px;
    line-height: 18px;
  }
  .voucher-code {
    width: 60%;
    box-sizing: border-box;
    padding: 5px 5px 2px;
    margin: 0 auto 10px;
    border: solid 1px #000;
    background-color: #eee;
    font-size: 15px;
    line-height: 30px;
    &:after {
      display: inline-block;
      padding: 0 0 0 5px;
      font-family: @icon;
      content: '\f24d';
      transform: scaleY(-1);
      vertical-align: bottom;
    }
  }
  .voucher-link {
    display: inline-block;
    min-width: 70%;
    box-sizing: border-box;
    margin: 0 auto 10px;
    padding: 5px 0 2px;
    line-height: 30px;
  }
  .voucher-countdown {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 5px;
    margin: 0;
    .countdown-wrapper {
      .days {
        display: none;
      }
      .hours:after, .minutes:after {
        content: ':';
      }
    }
  }
  .voucher-hint {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0;
    padding-top: 10px;
    .sans-serif-regular();
    font-size: 10px;
    color: @white;
  }
}
@media screen and (min-width: 360px){
  #win--container {
    h2 {
      margin: 10px 0 15px;
      font-size: 22px;
      line-height: 28px;
    }
    .voucher-name {
      font-size: 15px;
      line-height: 20px;
    }
    .voucher-code {
      font-size: 15px;
      line-height: 30px;
    }
  }
}
@media screen and (min-width: 375px){
  #win--container {
    h2 {
      margin: 10px 0 20px;
      font-size: 23px;
    }
    .voucher-name {
      margin: 0 0 22px;
    }
  }
}
@media screen and (min-width: 414px){
  #win--container {
    h2 {
      margin: 15px 0 20px;
      font-size: 25px;
      line-height: 30px;
    }
    .voucher-name {
      margin: 0 0 25px;
    }
  }
}
@media screen and (min-width: 768px){
  #win--container {
    h2 {
      margin: 25px auto 30px;
      max-width: 50%;
      font-size: 45px;
      line-height: 55px;
    }
    .voucher-name {
      max-width: 70%;
      margin: 0 auto 40px;
      font-size: 25px;
      line-height: 28px;
    }
    .voucher-code {
      font-size: 25px;
      line-height: 50px;
    }
    .voucher-link {
      min-width: none;
      margin: 20px auto 10px;
      padding: 5px 30px 2px;
      font-size: 25px;
      line-height: 50px;
    }
    .voucher-hint {
      padding-top: 20px;
      font-size: 15px;
    }
    .voucher-countdown {
      font-size: 15px;
    }
  }
}
@media screen and (min-width: 800px){
  #win--container {
    h2 {
      max-width: none;
      margin: 0 auto 10px;
      font-size: 16px;
      line-height: 20px;
    }
    .voucher-name {
      max-width: none;
      margin: 0 0 15px;
      font-size: 12px;
      line-height: 15px;
    }
    .voucher-code {
      margin: 0 auto 10px;
      font-size: 12px;
      line-height: 24px;
    }
    .voucher-link {
      margin: 0;
      font-size: 12px;
      line-height: 24px;
    }
    .voucher-hint {
      padding-top: 20px;
      font-size: 10px;
      color: @darkgrey;
    }
    .voucher-countdown {
      font-size: 10px;
    }
  }
}
@media screen and (min-width: 1024px){
  #win--container {
    h2 {
      margin: 10px 0 15px;
      font-size: 20px;
      line-height: 35px;
    }
    .voucher-name {
      margin: 0 0 15px;
      font-size: 15px;
      line-height: 20px;
    }
    .voucher-hint {
      padding-top: 3px;
      color: @white;
    }
  }
}
@media screen and (min-width: 1200px){
  #win--container {
    h2 {
      margin: 10px 0 25px;
      font-size: 25px;
      line-height: 30px;
    }
    .voucher-name {
      margin: 0 0 25px;
    }
    .voucher-code {
      font-size: 15px;
      line-height: 30px;
    }
    .voucher-link {
      font-size: 15px;
      line-height: 30px;
    }
    .voucher-hint {
      padding-top: 2px;
    }
  }
}