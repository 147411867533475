// rectangular
.image-teasers-rectangular {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  margin: 10px 5px;
  a {
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    color: @darkgrey;
    text-decoration: none;
    &:hover {
      .buttonshadow();
    }
    span {
      position: absolute;
      left: 10px;
      bottom: 10px;
      .sans-serif-bold();
      &:after {
        padding-left: 5px;
        .icon();
        font-size: 10px;
        content: '\f054';
      }
    }
  }
}
@media screen and (min-width: 400px) {
  .image-teasers-rectangular {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }
}
@media screen and (min-width: 640px) {
  .image-teasers-rectangular {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 769px) {
  .image-teasers-rectangular {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin: 20px 10px;
  }
}
@media screen and (min-width: 1024px) {
  .image-teasers-rectangular {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}