// main heading in page-content

.cms-page-view {
  .page-content {
    h1 {
      margin: 10px 0 15px;
      font-size: 20px;
      line-height: 30px;
    }
    h2 {
      margin: 30px 0 10px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
@media screen and (min-width: 480px){
  .cms-page-view {
    .page-content {
      h1 {
        padding: 0 5px;
      }
    }
  }
}
@media screen and (min-width: 768px){
  .cms-page-view {
    .page-content {
      h1 {
        margin: 10px 0;
        padding: 0 10px;
        font-size: 22px;
        line-height: 33px;
      }
      h2 {
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
}
@media screen and (min-width: 1024px){
  .cms-page-view {
    .page-content {
      h1 {
        margin: 0 0 15px;
        line-height: 36px;
      }
      h2 {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

// onpage navigation
.op-nav {
  display: block;
  padding: 0;
  width: 100%;
  float: left;
  ul {
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    float: left;
    list-style: none;
    li {
      display: block;
      box-sizing: border-box;
      margin: 0;
      padding: 5px;
      a {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        font-size: 13px;
        line-height: 20px;
        &:after {
          display: inline;
          padding: 0 0 0 5px;
          content: '>';
        }
      }
    }
  }
}
@media screen and (min-width: 768px){
  .op-nav {
    ul {
      width: 50%;
    }
  }
}
@media screen and (min-width: 1024px){
  .op-nav {
    ul {
      width: 25%;
      li {
        padding: 0 10px;
      }
    }
  }
}


// CMS Columns
.cms-row {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  &:after {
    display: block;
    width: 100%;
    clear: both;
    content: ' ';
    font-size: 0;
    line-height: 0;
  }
  .cms-col {
    position: relative;
    box-sizing: border-box;
    padding: 0 5px;
    margin: 0;
    &.block {
      margin: 0;
    }
    &.col-narrow,
    &.col-wide,
    &.col-full {
      width: 100%;
    }
    &.col-narrow {
      img {
        width: 100%;
      }
      .video {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        padding-top: 0;
        height: 0;
        overflow: hidden;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
@media screen and (min-width: 480px) {
  .cms-row {
    .cms-col {
      &.col-narrow {
        width: 50%;
        float: left;
      }
    }
  }
}
@media screen and (min-width: 640px) {
  .cms-row {
    .cms-col {
      &.col-narrow {
        width: 40%;
        float: left;
      }
      &.col-wide {
        width: 60%;
        float: left;
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .cms-row {
    .cms-col {
      padding: 0 10px;
      &.col-narrow {
        width: percentage((1/3));
        float: left;
      }
      &.col-wide {
        width: percentage((2/3));
        float: left;
      }
    }
  }
}


// CMS Grid-Lists
.cms-grid {
  li {
    box-sizing: border-box;
    width: 50%;
    padding: 10px;
    margin: 0 0 20px;
    float: left;
    text-align: center;
    list-style: none;
    border: 2px solid @white;
    &:hover {
      border-color: #efefef;
    }
  }
}
@media screen and (min-width: 480px) {
  .cms-grid {
    li {
      width: percentage((1/3));
    }
  }
}
@media screen and (min-width: 769px) {
  .cms-grid {
    li {
      width: percentage((1/4));
      padding: 10px;
    }
  }
}
@media screen and (min-width: 1025px) {
  .cms-grid {
    li {
      width: percentage((1/4));
    }
  }
}
@media screen and (min-width: 1281px) {
  .cms-grid {
    li {
      width: percentage((1/5));
    }
  }
}


.cs-header {
  display: grid;
  grid-template-columns: 1fr;
  .col {
    width: auto;
    float: none;
    &:nth-child(3) {
      display: none;
    }
    p {
      margin: 0 0 5px;
    }
    strong {
      white-space: nowrap;
    }
    .text {
       position: absolute;
       top: 0;
       padding: 20px 10px;
    }
  }
}
.cs-content {
  display: grid;
  grid-template-columns: 1fr;
  .col {
    width: auto;
    float: none;
    p {
      margin: 10px 0;
    }
  }
}

@media screen and (min-width: 481px) {
  .cs-header {
    grid-template-columns: 1fr 1fr;
    .col {
      min-height: 250px;
    }
  }
  .cs-content {
    grid-template-columns: 1fr 1fr;
    .col {
      min-height: 426px;
    }
  }
}

@media screen and (min-width: 621px) {
  .cs-header {
    .col {
      min-height: 300px;
    }
  }
  .cs-content {
    grid-template-columns: 1fr 1fr 1fr;
    .col {
      min-height: 425px;
    }
  }
}

@media screen and (min-width: 769px) {

  .cs-header {
    grid-template-columns: 1fr 1fr 1fr;
    .col {
      &:nth-child(3) {
        display: block;
      }
    }
  }
  .cs-content {
    .col {
      min-height: 440px;
    }
  }
}

@media screen and (min-width: 1025px) {
  .cs-header {
    .col {
      p {
        margin: 0 0 10px;
        font-size: 14px;
      }
    }
  }
  .cs-content {
    .col {
      min-height: 470px;
    }
  }
}

// Programmvorschau
#epg {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  float: left;
  margin: 0 0 40px;
  .epg-day {
    width: 100%;
    margin: 0;
    float: left;
    height: auto;
    h3 {
      margin: 20px 0 0;
      padding: 5px 10px !important;
      background-color: #efefef;
      cursor: pointer;
      font-family: @serif-normal;
      text-transform: none;
      &:after {
        display: inline-block;
        float: right;
        font-family: @icon;
        content: '\f077';
      }
    }
    .epg-table {
      position: relative;
      width: 100%;
      th {
        font-size: 13px;
        font-weight: bold;
        line-height: 24px;
        padding: 2px 0 0;
      }
      td {
        padding: 5px 8px;
        border-bottom: 1px solid #ccc;
        line-height: 27px;
        vertical-align: top;
        position: relative;
        &.amayani {
          background: url('../images/icon-amayani-b.png') 10px 10px no-repeat;
          text-indent: -9999px;
        }
        &.ring {
          background: url('../images/ringgroesse_tableiste.png') 10px 10px no-repeat;
          a {
            padding-left: 30px;
          }
        }
        img {
          float: left;
          margin: 0 10px 0 0;
        }
        &.cell-time {
          font-size: 11px;
          font-weight: bold;
          color: @white;
          background: @pink;
          width: 40px;
          text-align: right;
        }
        &.cell-show {
          font-size: 12px;
          small {
            font-size: 10px;
            line-height: 15px;
            display: block;
          }
          &.amayani small {
            display: none;
          }
          a {
            display: block;
            overflow: auto;
            color: @pink;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
              span {
                display: block;
              }
            }
            span {
              display: none;
              background: @white;
              position: absolute;
              left: 85px;
              top: 30px;
              text-indent: 0;
            }
          }
        }
      }
      .image {
        td {
          height: 65px;
          &.amayani {
            background: url('../images/icon-amayani-b.png') 85px 10px no-repeat;
          }
          &.ring {
            background: url('../images/ringgroesse_tableiste.png') 85px 10px no-repeat;
            a {
              padding-left: 0;
            }
            img {
              margin: 0 35px 0 0;
            }
          }
        }
      }
      &.morning th,
      &.afternoon th,
      &.vormittag th,
      &.abend th {
        background: #c6c5c4;
      }
      &.noon th,
      &.evening th,
      &.nachmittag th,
      &.nacht th {
        background: #57585a;
        color: @white;
      }
    }
    &.closed {
      h3 {
        &:after {
          content: '\f078';
        }
      }
      .epg-table {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 641px) {
  #epg {
    .epg-day {
      .epg-table {
        width: 50%;
        float: left;
        border-right: 1px solid #ccc;
        &.noon th,
        &.evening th,
        &.nachmittag th,
        &.nacht th {
          border-right: 1px solid #57585a;
        }
      }
    }
  }
}
@media screen and (min-width: 1281px) {
  #epg {
    .epg-day {
      .epg-table {
        width: percentage((1/4));
      }
    }
  }
}


// videothek
#videothek {
  width: 100%;
  .block {
    background: #f7f7f7;
  }
  .block-video {
    background: #575755;
    color: @white;
  }
  .block h2 {
    border-bottom: 1px solid #ddd;
    padding: 0 0 10px;
    margin: 0 0 10px;
  }
  #videothek_categories {
    width: 100%;
    margin: 0 0 20px;
    overflow: auto;
    float: none;
    li {
      span {
        font-size: 90%;
        line-height: 20px;
        color: @pink;
        cursor: pointer;
        &:hover,
        &.active {
          text-decoration: underline;
        }
      }
    }
  }
  #videothek_box {
    width: 100%;
    overflow: auto;
    float: none;
  }
  #videothek_videos {
    width: 100%;
    overflow: auto;
    float: none;
    li {
      width: 100%;
      float: left;
      font-size: 11px;
      background: none;
      padding: 10px 0;
      border-bottom: 1px solid #ddd;
      cursor: pointer;
      display: block;
      li {
        &.more,
        &.less {
          margin: 0;
          padding: 5px 0 0;
          border-bottom: none;
        }
        li:first-child {
          padding: 0 0 10px;
        }
        .videothek-link {
          margin: 0 auto;
          display: block;
        }
        .videothek-link:hover {
          text-decoration: underline;
        }
        img {
          width: 100px;
          height: auto;
          margin: 0 10px 0 0;
          float: left;
        }
      }
    }
  }
  #videothek_player {
    width: 100%;
    overflow: auto;
    z-index: 100;
    float: left;
  }
  #videothek_categories {
    margin: 0 0 20px;
  }
}
@media screen and (min-width: 769px) {
  #videothek {
    .videothek-main {
      box-sizing: border-box;
      width: 75%;
      padding: 0 5px 0 0;
      float: left;
    }
    .videothek-sidebar {
      box-sizing: border-box;
      width: percentage((1/4));
      padding: 0 0 0 5px;
      float: right;
    }
  }
}
@media screen and (min-width: 1025px) {
  #videothek {
    .videothek-main {
      padding: 0 10px 0 0;
    }
    .videothek-sidebar {
      padding: 0 0 0 10px;
    }
  }
}
@media screen and (min-width: 1281px) {
  #videothek {
    .videothek-main {
      padding: 0 15px 0 0;
    }
    .videothek-sidebar {
      padding: 0 0 0 15px;
    }
  }
}
@media screen and (min-width: 1441px) {
  #videothek {
    .videothek-main {
      padding: 0 20px 0 0;
    }
    .videothek-sidebar {
      padding: 0 0 0 20px;
    }
  }
}


// magazine
.magazine {
  h2 {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 15px 5px 10px;
    float: left;
    text-align: center;
  }
  p {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0 5px;
    float: left;
    text-align: center;
  }
  ul {
    li {
      position: relative;
      box-sizing: border-box;
      width: percentage((1/2));
      height: 250px;
      margin: 0 0 10px;
      float: left;
      a {
        display: block;
        position: absolute;
        top: 0;
        left: 5px;
        right: 5px;
        bottom: 0;
        border: 1px solid @lightgrey;
        text-align: center;
        strong {
          display: block;
          margin: 10px 0;
          .sans-serif-regular();
          font-size: 11px;
          text-transform: uppercase;
        }
        img {
          width: 100%;
          height: auto;
          max-width: 120px;
          max-height: 170px;
          margin: 0 auto;
          .shadow();
        }
        span {
          display: block;
          margin: 10px 0;
          .sans-serif-bold();
          font-size: 10px;
        }
      }
    }
  }
}
@media screen and (min-width: 580px){
  .magazine {
    ul {
      li {
        width: percentage((1/3));
      }
    }
  }
}
@media screen and (min-width: 641px){
  .magazine {
    h2 {
      text-align: left;
    }
    p {
      text-align: left;
    }
  }
}
@media screen and (min-width: 768px){
  .magazine {
    ul {
      li {
        width: percentage((1/4));
      }
    }
  }
}
@media screen and (min-width: 769px){
  .magazine {
    h2 {
      padding: 30px 10px 10px;
    }
    p {
      padding: 0 10px;
    }
    ul {
      li {
        margin: 0 0 20px;
        a {
          left: 10px;
          right: 10px;
        }
      }
    }
  }
}
@media screen and (min-width: 1024px){
  .magazine {
    h2 {}
    p {}
    ul {
      li {
        width: percentage((1/6));
      }
    }
  }
}

// dato-cms-page
.cms-page-view {
  article.cms-page {
    padding: 5px;
    h1 {
      padding: 0;
    }
    .op-nav {
      float: none;
      margin: 0 0 30px;
      padding: 15px 0;
      border-top: 1px solid @mediumgrey;
      border-bottom: 1px solid @mediumgrey;
      span {
        display: inline-block;
        cursor: pointer;
        color: @pink;
        .sans-serif-medium;
        font-size: 15px;
        line-height: 28px;
        text-transform: uppercase;
        &:after {
          padding-left: 10px;
          font-family: @icon;
          font-size: 12px;
          content: '\f078';
        }
      }
      ul {
        display: none;
        float: none;
        width: 100%;
        li {
          padding: 5px 0;
          a {
            .sans-serif-medium;
            &:after {
              font-family: @icon;
              font-size: 10px;
              content: '\f054';
            }
          }
        }
      }
      &.open {
        span {
          color: @darkgrey;
          &:after {
            content: '\f077';
          }
        }
        ul {
          display: block;
        }
      }
    }
    section {
      p:last-child {
        margin-top: 30px;
      }
    }
  }
}
@media screen and (min-width: 480px){
  .cms-page-view {
    article.cms-page {
      .op-nav {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        span {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 2;
        }
        ul:nth-of-type {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 2;
          grid-row-end: 3;
        }
        ul:nth-of-type(2) {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 3;
          grid-row-end: 4;
        }
        ul:nth-of-type(3) {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 2;
          grid-row-end: 3;
        }
        ul:nth-of-type(4) {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 3;
          grid-row-end: 4;
        }
      }
    }
  }
}
@media screen and (min-width: 768px){
  .cms-page-view {
    article.cms-page {
      .op-nav {
        span {
          display: none;
        }
        ul {
          display: block;
        }
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 20px;
        ul:first-of-type {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 1;
          grid-row-end: 2;
        }
        ul:nth-of-type(2) {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 2;
        }
        ul:nth-of-type(3) {
          grid-column-start: 3;
          grid-column-end: 4;
          grid-row-start: 1;
          grid-row-end: 2;
        }
        ul:nth-of-type(4) {
          grid-column-start: 4;
          grid-column-end: 5;
          grid-row-start: 1;
          grid-row-end: 2;
        }
      }
    }
  }
}
@media screen and (min-width: 769px){
  .cms-page-view {
    article.cms-page {
      padding: 10px;
    }
  }
}

// moderatoren / experten
.cms-page.presenter {
  .presenter {
    display: flex;
    flex-direction: column;
    margin: 0 0 45px;
    padding: 15px 0 0;
    picture {
      order: 2;
      img {
        max-width: 340px;
        margin: 0 auto 15px;
      }
    }
    h2 {
      order: 1;
      margin: 0 0 15px;
      font-size: 20px;
      line-height: 21px;
      img {
        height: 1.05em;
      }
    }
    p {
      order: 3;
      margin: 0;
      b {
        display: block;
        margin: 0 0 15px;
        line-height: 15px;
      }
    }
  }
}
@media screen and (min-width: 640px){
  .cms-page.presenter {
    .presenter {
      display: grid;
      grid-template-columns: 340px auto;
      grid-column-gap: 15px;
      picture {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 4;
      }
      h2 {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      p {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
        font-size: 15px;
        line-height: 25px;
      }
      p:nth-of-type(2) {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 4;
      }
    }
  }
}
@media screen and (min-width: 768px){
  .cms-page.presenter {
    .presenter {
      grid-column-gap: 20px;
      picture {
        grid-row-start: 1;
      }
      h2 {
        grid-column-start: 2;
      }
    }
  }
}

// faq
.bietnummern {
  margin: 0 0 20px;
  width: 100%;
  clear: both;
  .country-flag {
    margin: 0 10px 0 0;
  }
  table {
    vertical-align: top;
  }
  strong {
    white-space: nowrap;
  }
  .col-2-set .col {
    width: auto;
  }
  strong {
    display: inline-block;
    .sans-serif-bold();
    line-height: 100%;
  }
}


@media screen and (min-width: 767px) {
  .cms-so-funktionierts .col-2-set .col {
    width: 47%;
    padding: 0.5em;
  }
}

// ringsize
#ring_size_table {
  width: 100%;
  th {
    padding: 0.5em;
    text-align: left;
    display: none;
  }
  td {
    border-top: 1px solid #ccc;
    padding: 0.5em;
  }
}
.de #ring_size_table .de th,
.es #ring_size_table .es th,
.en #ring_size_table .en th,
.fr #ring_size_table .fr th,
.it #ring_size_table .it th,
.nl #ring_size_table .nl th {
  display: table-cell;
}

// Lexikon
.lexikon {
  h3 {
    margin: 0 0 10px;
  }
}

.teaser-boxes.col-3-set.lexikon-teaser {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  .col {
    width: auto;
    float: none;
  }
}
@media screen and (min-width: 640px){
  .teaser-boxes.col-3-set.lexikon-teaser {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 769px){
  .teaser-boxes.col-3-set.lexikon-teaser {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.box-white {
  .image img {
    width: 120px;
    float: left;
    margin: 0px 15px 15px 0;
  }
  .info h3 {
    margin-top: 0;
  }
}

#lexikontext {
  margin: 40px 0 0;
  .show {
    margin: 0;
  }
  .more.show {
    margin-top: 10px;
  }
}

#inhalte {
  .level1 li {
    width: 50%;
  }
  .level2 li {
    width: 100%;
  }
}

#geburtsstein h2 {
  margin: 10px 0 20px;
}

@media screen and (min-width: 800px) {
  #inhalte {
    .level1 li.abc {
      width: 25%;
    }
  }
}
.lexikon-table {
  td {
    min-height: 180px;
    vertical-align: bottom;
  }
  img {
    display: inline-block !important;
    max-width: 80px !important;
    border: 0.5em solid #fff !important;
  }
}
@media screen and (min-width: 600px) {
  .lexikon-table {
    img {
      display: inline-block !important;
      max-width: 100% !important;
      border: 2em solid #fff !important;
    }
  }
}
// edelsteinfarben
#colors {
  width: 100%;
  float: left;
  box-sizing: border-box;
  .media-box {
    width: 100%;
    float: left;
    box-sizing: border-box;
    ul {
      width: 100%;
      float: left;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      li {
        width: 50%;
        display: block;
        float: left;
        height: 160px;
        text-align: center;
        a {
          color: @darkgrey;
          picture img {
            max-width: 120px;
            max-height: 120px;
            margin: 0 auto;
          }
        }
        .h4 {
          text-transform: none;
          font-size: 12px;
          line-height: 20px;
          height: 40px;
        }
        .img-wrapper {
          position: relative;
          width: 120px;
          height: 120px;
          overflow: hidden;
          margin: 0 auto;
          img {
            width: 600px !important;
            max-width: 600px !important;
            height: 960px !important;
            position: absolute;

            // schwarz
            &.haematit { top: 0; left: 0; }
            &.onyx { top: 0; left: -120px; }
            &.schwarzer-diamant { top: 0; left: -240px; }
            &.schwarzer-spinell { top: 0; left: -360px; }
            &.schwarzer-stern-saphir { top: 0; left: -480px; }
            // blau / violett
            &.amethyst { top: 0; left: 0; }
            &.apatit { top: 0; left: -120px; }
            &.aquamarin { top: 0; left: -240px; }
            &.aquamarin-katzenauge { top: 0; left: -360px; }
            &.axinit { top: 0; left: -480px; }
            &.blaubeer-quarz { top: -120px; left: 0; }
            &.blauer-diamant { top: -120px; left: -120px; }
            &.blauer-feueropal { top: -120px; left: -240px; }
            &.blaugruener-turmalin { top: -120px; left: -360px; }
            &.blauer-saphir { top: -120px; left: -480px; }
            &.blauer-spinell { top: -240px; left: 0; }
            &.chalzedon { top: -240px; left: -120px; }
            &.dumortierit { top: -240px; left: -240px; }
            &.hemimorphit { top: -240px; left: -360px; }
            &.himmelblauer-topas { top: -240px; left: -480px; }
            &.indigolith { top: -360px; left: 0; }
            &.iolith { top: -360px; left: -120px; }
            &.kanchanaburi-saphir { top: -360px; left: -240px; }
            &.karibikblauer-apatit { top: -360px; left: -360px; }
            &.kyanit { top: -360px; left: -480px; }
            &.lapislazuli { top: -480px; left: 0; }
            &.lilien-quarz { top: -480px; left: -120px; }
            &.londonblauer-topas { top: -480px; left: -240px; }
            &.meeres-topas { top: -480px; left: -360px; }
            &.mitternachtsblauer-saphir { top: -480px; left: -480px; }
            &.mondblauer-quarz { top: -600px; left: 0; }
            &.neonblauer-apatit { top: -600px; left: -120px; }
            &.purpur-saphir { top: -600px; left: -360px; }
            &.purpur-spinell { top: -600px; left: -480px; }
            &.ratanakiri-zirkon { top: -720px; left: 0; }
            &.schweizblauer-topas { top: -720px; left: -120px; }
            &.stern-saphir { top: -720px; left: -240px; }
            &.tansanit { top: -720px; left: -360px; }
            &.trauben-topas { top: -720px; left: -480px; }
            &.tuerkis { top: -840px; left: 0; }
            // weiß / farblos
            &.danburit { top: 0; left: 0; }
            &.diamant { top: 0; left: -120px; }
            &.goshenit { top: 0; left: -240px; }
            &.petalit { top: 0; left: -360px; }
            &.weisser-quarz { top: 0; left: -480px; }
            &.weisser-saphir { top: -120px; left: 0; }
            &.weisser-topas { top: -120px; left: -120px; }
            &.weisser-zirkon { top: -120px; left: -240px; }
            // grün
            &.amazonit { top: 0; left: 0; }
            &.amblygonit { top: 0; left: -120px; }
            &.chrom-turmalin { top: 0; left: -240px; }
            &.chrysopras { top: 0; left: -360px; }
            &.demantoid { top: 0; left: -480px; }
            &.fluorit { top: -120px; left: 0; }
            &.gruener-amethyst { top: -120px; left: -120px; }
            &.gruener-diamant { top: -120px; left: -240px; }
            &.gruener-kunzit { top: -120px; left: -360px; }
            &.gruener-saphir { top: -120px; left: -480px; }
            &.gruener-turmalin { top: -240px; left: 0; }
            &.heliotrop { top: -240px; left: -120px; }
            &.jade { top: -240px; left: -240px; }
            &.kiwi-topas { top: -240px; left: -360px; }
            &.malachit { top: -240px; left: -480px; }
            &.merelani-mint-granat { top: -360px; left: 0; }
            &.moldavit { top: -360px; left: -120px; }
            &.peridot { top: -360px; left: -360px; }
            &.russischer-diopsid { top: -360px; left: -480px; }
            &.smaragd { top: -480px; left: 0; }
            &.trapiche-smaragd { top: -480px; left: -120px; }
            &.tsavorit { top: -480px; left: -240px; }
            // mehrfarbig
            &.abendroter-mystik-topas { top: 0; left: 0; }
            &.alexandrit { top: 0; left: -120px; }
            &.alexandrit-katzenauge { top: 0; left: -240px; }
            &.ametrin { top: 0; left: -360px; }
            &.aventurin { top: 0; left: -480px; }
            &.boulder-opal { top: -120px; left: 0; }
            &.erdbeer-quarz { top: -120px; left: -120px; }
            &.farbwechsel-granat { top: -120px; left: -240px; }
            &.farbwechsel-saphir { top: -120px; left: -360px; }
            &.graffiti-quarz { top: -120px; left: -480px; }
            &.halbschwarzer-opal { top: -240px; left: 0; }
            &.jaspis { top: -240px; left: -120px; }
            &.jelly-opal { top: -240px; left: -240px; }
            &.labradorit { top: -240px; left: -360px; }
            &.matrix-opal { top: -240px; left: -480px; }
            &.medusa-quarz { top: -360px; left: 0; }
            &.mehrfarbiger-amethyst { top: -360px; left: -120px; }
            &.mehrfarbiger-citrin { top: -360px; left: -240px; }
            &.mehrfarbiger-fluorit { top: -360px; left: -360px; }
            &.mehrfarbiger-kunzit { top: -360px; left: -480px; }
            &.mehrfarbiger-quarz { top: -480px; left: 0; }
            &.mehrfarbiger-tansanit { top: -480px; left: -120px; }
            &.mehrfarbiger-turmalin { top: -480px; left: -240px; }
            &.mondlicht-topas { top: -480px; left: -360px; }
            &.mondstein { top: -480px; left: -480px; }
            &.mystik-topas { top: -600px; left: 0; }
            &.neptun-topas { top: -600px; left: -120px; }
            &.perlmutt { top: -600px; left: -240px; }
            &.regenbogen-mondstein { top: -600px; left: -360px; }
            &.sardonyx { top: -600px; left: -480px; }
            &.schwarzer-opal { top: -720px; left: 0; }
            &.suedsee-perle { top: -720px; left: -120px; }
            &.suesswasserzuchtperle { top: -720px; left: -240px; }
            &.tahiti-perle { top: -720px; left: -360px; }
            &.turmalin-quarz { top: -720px; left: -480px; }
            &.weisser-opal { top: -840px; left: 0; }
            &.zultanit { top: -840px; left: -120px; }
            &.zultanit-katzenauge { top: -840px; left: -240px; }
            // rot / pink
            &.edelroter-spinell { top: 0; left: 0; }
            &.flamingo-topas { top: 0; left: -120px; }
            &.kirsch-topas { top: 0; left: -240px; }
            &.kunzit { top: 0; left: -360px; }
            &.lavendel-amethyst { top: 0; left: -480px; }
            &.morganit { top: -120px; left: 0; }
            &.mosambik-granat { top: -120px; left: -120px; }
            &.pinkfarbener-danburit { top: -120px; left: -240px; }
            &.pinkfarbener-diamant { top: -120px; left: -360px; }
            &.pinkfarbener-saphir { top: -120px; left: -480px; }
            &.pinkfarbener-spinell { top: -240px; left: 0; }
            &.pinkfarbener-turmalin { top: -240px; left: -120px; }
            &.pyrop { top: -240px; left: -240px; }
            &.rhodochrosit { top: -240px; left: -360px; }
            &.rhodolith { top: -240px; left: -480px; }
            &.rhodonit { top: -360px; left: 0; }
            &.rosenquarz { top: -360px; left: -120px; }
            &.roter-diamant { top: -360px; left: -240px; }
            &.rubellit { top: -360px; left: -360px; }
            &.rubin { top: -360px; left: -480px; }
            &.stern-rubin { top: -480px; left: 0; }
            &.trauben-topas { top: -480px; left: -120px; }
            // gelb / braun
            &.abendroter-saphir { top: 0; left: 0; }
            &.achat { top: 0; left: -120px; }
            &.aktinolith-katzenauge { top: 0; left: -240px; }
            &.andalusit { top: 0; left: -360px; }
            &.andratit { top: 0; left: -480px; }
            &.bernstein { top: -120px; left: 0; }
            &.champagner-diamant { top: -120px; left: -120px; }
            &.chondrodit { top: -120px; left: -240px; }
            &.chrysoberyll { top: -120px; left: -360px; }
            &.chrysoberyll-katzenauge { top: -120px; left: -480px; }
            &.citrin { top: -240px; left: 0; }
            &.danburit-katzenauge { top: -240px; left: -120px; }
            &.feueropal { top: -240px; left: -240px; }
            &.gelber-beryll { top: -240px; left: -360px; }
            &.gelber-diamant { top: -240px; left: -480px; }
            &.gelber-saphir { top: -360px; left: 0; }
            &.heliodor { top: -360px; left: -120px; }
            &.hessonit { top: -360px; left: -240px; }
            &.kaiserlicher-topas { top: -360px; left: -360px; }
            &.karneol { top: -360px; left: -480px; }
            &.klinohumit { top: -480px; left: 0; }
            &.kupfer-turmalin { top: -480px; left: -120px; }
            &.limonen-citrin { top: -480px; left: -240px; }
            &.mandarin-granat { top: -480px; left: -360px; }
            &.markasit { top: -480px; left: -480px; }
            &.oranger-saphir { top: -600px; left: 0; }
            &.ouro-verde-quarz { top: -600px; left: -120px; }
            &.padparadschafarbener-saphir { top: -600px; left: -240px; }
            &.padparadscha-saphir { top: -600px; left: -360px; }
            &.rauchquarz { top: -600px; left: -480px; }
            &.rutilquarz { top: -720px; left: 0; }
            &.safran-zirkon { top: -720px; left: -120px; }
            &.skapolith-katzenauge { top: -720px; left: -240px; }
            &.sonnenstein { top: -720px; left: -360px; }
            &.sonnen-topas { top: -720px; left: -480px; }
            &.spessartin { top: -840px; left: 0; }
            &.sphalerit { top: -840px; left: -120px; }
            &.sphen { top: -840px; left: -240px; }
            &.tigerauge { top: -840px; left: -360px; }
            &.zimt-zirkon { top: -840px; left: -480px; }
          }
        }
      }
    }

    &.blau {
      ul {
        li {
          .img-wrapper {
            img {
              &.paraiba-turmalin { top: -600px; left: -240px; }
            }
          }
        }
      }

    }
    &.gruen {
      ul {
        li {
          .img-wrapper {
            img {
              &.paraiba-turmalin { top: -360px; left: -240px; }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 769px) {
  #colors,
  .gemstone-gallery {
    .media-box {
      ul {
        li {
          width: percentage((1/3));
        }
      }
    }
  }
}
@media screen and (min-width: 1025px) {
  #colors,
  .gemstone-gallery {
    .media-box {
      ul {
        li {
          width: percentage((1/4));
        }
      }
    }
  }
}
@media screen and (min-width: 1281px) {
  #colors,
  .gemstone-gallery {
    .media-box {
      ul {
        li {
          width: percentage((1/5));
        }
      }
    }
  }
}

.media-box.gemstone-gallery {
  width: 100%;
  float: left;
  box-sizing: border-box;
  ul {
    width: 100%;
    float: left;
    box-sizing: border-box;
    margin: 0 0 40px;
    padding: 0;
    li {
      width: 50%;
      display: block;
      float: left;
      height: 160px;
      text-align: center;
      a {
        color: @darkgrey;
        picture img {
          max-width: 120px;
          max-height: 120px;
          margin: 0 auto;
        }
      }
      .h4 {
        display: block;
        text-transform: none;
        font-size: 12px;
        line-height: 20px;
        height: 40px;
      }
      .img-wrapper {
        position: relative;
        width: 120px;
        height: 120px;
        overflow: hidden;
        margin: 0 auto;
        img {
          width: 600px !important;
          max-width: 600px !important;
          height: 960px !important;
          position: absolute;

          // schwarz
          &.haematit { top: 0; left: 0; }
          &.onyx { top: 0; left: -120px; }
          &.schwarzer-diamant { top: 0; left: -240px; }
          &.schwarzer-spinell { top: 0; left: -360px; }
          &.schwarzer-stern-saphir { top: 0; left: -480px; }
          // blau / violett
          &.amethyst { top: 0; left: 0; }
          &.apatit { top: 0; left: -120px; }
          &.aquamarin { top: 0; left: -240px; }
          &.aquamarin-katzenauge { top: 0; left: -360px; }
          &.axinit { top: 0; left: -480px; }
          &.blaubeer-quarz { top: -120px; left: 0; }
          &.blauer-diamant { top: -120px; left: -120px; }
          &.blauer-feueropal { top: -120px; left: -240px; }
          &.blaugruener-turmalin { top: -120px; left: -360px; }
          &.blauer-saphir { top: -120px; left: -480px; }
          &.blauer-spinell { top: -240px; left: 0; }
          &.chalzedon { top: -240px; left: -120px; }
          &.dumortierit { top: -240px; left: -240px; }
          &.hemimorphit { top: -240px; left: -360px; }
          &.himmelblauer-topas { top: -240px; left: -480px; }
          &.indigolith { top: -360px; left: 0; }
          &.iolith { top: -360px; left: -120px; }
          &.kanchanaburi-saphir { top: -360px; left: -240px; }
          &.karibikblauer-apatit { top: -360px; left: -360px; }
          &.kyanit { top: -360px; left: -480px; }
          &.lapislazuli { top: -480px; left: 0; }
          &.lilien-quarz { top: -480px; left: -120px; }
          &.londonblauer-topas { top: -480px; left: -240px; }
          &.meeres-topas { top: -480px; left: -360px; }
          &.mitternachtsblauer-saphir { top: -480px; left: -480px; }
          &.mondblauer-quarz { top: -600px; left: 0; }
          &.neonblauer-apatit { top: -600px; left: -120px; }
          &.purpur-saphir { top: -600px; left: -360px; }
          &.purpur-spinell { top: -600px; left: -480px; }
          &.ratanakiri-zirkon { top: -720px; left: 0; }
          &.schweizblauer-topas { top: -720px; left: -120px; }
          &.stern-saphir { top: -720px; left: -240px; }
          &.tansanit { top: -720px; left: -360px; }
          &.trauben-topas { top: -720px; left: -480px; }
          &.tuerkis { top: -840px; left: 0; }
          // weiß / farblos
          &.danburit { top: 0; left: 0; }
          &.diamant { top: 0; left: -120px; }
          &.goshenit { top: 0; left: -240px; }
          &.petalit { top: 0; left: -360px; }
          &.weisser-quarz { top: 0; left: -480px; }
          &.weisser-saphir { top: -120px; left: 0; }
          &.weisser-topas { top: -120px; left: -120px; }
          &.weisser-zirkon { top: -120px; left: -240px; }
          // grün
          &.amazonit { top: 0; left: 0; }
          &.amblygonit { top: 0; left: -120px; }
          &.chrom-turmalin { top: 0; left: -240px; }
          &.chrysopras { top: 0; left: -360px; }
          &.demantoid { top: 0; left: -480px; }
          &.fluorit { top: -120px; left: 0; }
          &.gruener-amethyst { top: -120px; left: -120px; }
          &.gruener-diamant { top: -120px; left: -240px; }
          &.gruener-kunzit { top: -120px; left: -360px; }
          &.gruener-saphir { top: -120px; left: -480px; }
          &.gruener-turmalin { top: -240px; left: 0; }
          &.heliotrop { top: -240px; left: -120px; }
          &.jade { top: -240px; left: -240px; }
          &.kiwi-topas { top: -240px; left: -360px; }
          &.malachit { top: -240px; left: -480px; }
          &.merelani-mint-granat { top: -360px; left: 0; }
          &.moldavit { top: -360px; left: -120px; }
          &.peridot { top: -360px; left: -360px; }
          &.russischer-diopsid { top: -360px; left: -480px; }
          &.smaragd { top: -480px; left: 0; }
          &.trapiche-smaragd { top: -480px; left: -120px; }
          &.tsavorit { top: -480px; left: -240px; }
          // mehrfarbig
          &.abendroter-mystik-topas { top: 0; left: 0; }
          &.alexandrit { top: 0; left: -120px; }
          &.alexandrit-katzenauge { top: 0; left: -240px; }
          &.ametrin { top: 0; left: -360px; }
          &.aventurin { top: 0; left: -480px; }
          &.boulder-opal { top: -120px; left: 0; }
          &.erdbeer-quarz { top: -120px; left: -120px; }
          &.farbwechsel-granat { top: -120px; left: -240px; }
          &.farbwechsel-saphir { top: -120px; left: -360px; }
          &.graffiti-quarz { top: -120px; left: -480px; }
          &.halbschwarzer-opal { top: -240px; left: 0; }
          &.jaspis { top: -240px; left: -120px; }
          &.jelly-opal { top: -240px; left: -240px; }
          &.labradorit { top: -240px; left: -360px; }
          &.matrix-opal { top: -240px; left: -480px; }
          &.medusa-quarz { top: -360px; left: 0; }
          &.mehrfarbiger-amethyst { top: -360px; left: -120px; }
          &.mehrfarbiger-citrin { top: -360px; left: -240px; }
          &.mehrfarbiger-fluorit { top: -360px; left: -360px; }
          &.mehrfarbiger-kunzit { top: -360px; left: -480px; }
          &.mehrfarbiger-quarz { top: -480px; left: 0; }
          &.mehrfarbiger-tansanit { top: -480px; left: -120px; }
          &.mehrfarbiger-turmalin { top: -480px; left: -240px; }
          &.mondlicht-topas { top: -480px; left: -360px; }
          &.mondstein { top: -480px; left: -480px; }
          &.mystik-topas { top: -600px; left: 0; }
          &.neptun-topas { top: -600px; left: -120px; }
          &.perlmutt { top: -600px; left: -240px; }
          &.regenbogen-mondstein { top: -600px; left: -360px; }
          &.sardonyx { top: -600px; left: -480px; }
          &.schwarzer-opal { top: -720px; left: 0; }
          &.suedsee-perle { top: -720px; left: -120px; }
          &.suesswasserzuchtperle { top: -720px; left: -240px; }
          &.tahiti-perle { top: -720px; left: -360px; }
          &.turmalin-quarz { top: -720px; left: -480px; }
          &.weisser-opal { top: -840px; left: 0; }
          &.zultanit { top: -840px; left: -120px; }
          &.zultanit-katzenauge { top: -840px; left: -240px; }
          // rot / pink
          &.edelroter-spinell { top: 0; left: 0; }
          &.flamingo-topas { top: 0; left: -120px; }
          &.kirsch-topas { top: 0; left: -240px; }
          &.kunzit { top: 0; left: -360px; }
          &.lavendel-amethyst { top: 0; left: -480px; }
          &.morganit { top: -120px; left: 0; }
          &.mosambik-granat { top: -120px; left: -120px; }
          &.pinkfarbener-danburit { top: -120px; left: -240px; }
          &.pinkfarbener-diamant { top: -120px; left: -360px; }
          &.pinkfarbener-saphir { top: -120px; left: -480px; }
          &.pinkfarbener-spinell { top: -240px; left: 0; }
          &.pinkfarbener-turmalin { top: -240px; left: -120px; }
          &.pyrop { top: -240px; left: -240px; }
          &.rhodochrosit { top: -240px; left: -360px; }
          &.rhodolith { top: -240px; left: -480px; }
          &.rhodonit { top: -360px; left: 0; }
          &.rosenquarz { top: -360px; left: -120px; }
          &.roter-diamant { top: -360px; left: -240px; }
          &.rubellit { top: -360px; left: -360px; }
          &.rubin { top: -360px; left: -480px; }
          &.stern-rubin { top: -480px; left: 0; }
          &.trauben-topas { top: -480px; left: -120px; }
          // gelb / braun
          &.abendroter-saphir { top: 0; left: 0; }
          &.achat { top: 0; left: -120px; }
          &.aktinolith-katzenauge { top: 0; left: -240px; }
          &.andalusit { top: 0; left: -360px; }
          &.andratit { top: 0; left: -480px; }
          &.bernstein { top: -120px; left: 0; }
          &.champagner-diamant { top: -120px; left: -120px; }
          &.chondrodit { top: -120px; left: -240px; }
          &.chrysoberyll { top: -120px; left: -360px; }
          &.chrysoberyll-katzenauge { top: -120px; left: -480px; }
          &.citrin { top: -240px; left: 0; }
          &.danburit-katzenauge { top: -240px; left: -120px; }
          &.feueropal { top: -240px; left: -240px; }
          &.gelber-beryll { top: -240px; left: -360px; }
          &.gelber-diamant { top: -240px; left: -480px; }
          &.gelber-saphir { top: -360px; left: 0; }
          &.heliodor { top: -360px; left: -120px; }
          &.hessonit { top: -360px; left: -240px; }
          &.kaiserlicher-topas { top: -360px; left: -360px; }
          &.karneol { top: -360px; left: -480px; }
          &.klinohumit { top: -480px; left: 0; }
          &.kupfer-turmalin { top: -480px; left: -120px; }
          &.limonen-citrin { top: -480px; left: -240px; }
          &.mandarin-granat { top: -480px; left: -360px; }
          &.markasit { top: -480px; left: -480px; }
          &.oranger-saphir { top: -600px; left: 0; }
          &.ouro-verde-quarz { top: -600px; left: -120px; }
          &.padparadschafarbener-saphir { top: -600px; left: -240px; }
          &.padparadscha-saphir { top: -600px; left: -360px; }
          &.rauchquarz { top: -600px; left: -480px; }
          &.rutilquarz { top: -720px; left: 0; }
          &.safran-zirkon { top: -720px; left: -120px; }
          &.skapolith-katzenauge { top: -720px; left: -240px; }
          &.sonnenstein { top: -720px; left: -360px; }
          &.sonnen-topas { top: -720px; left: -480px; }
          &.spessartin { top: -840px; left: 0; }
          &.sphalerit { top: -840px; left: -120px; }
          &.sphen { top: -840px; left: -240px; }
          &.tigerauge { top: -840px; left: -360px; }
          &.zimt-zirkon { top: -840px; left: -480px; }
        }
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .media-box.gemstone-gallery {
    ul {
      li {
        width: percentage((1/3));
      }
    }
  }
}
@media screen and (min-width: 1025px) {
  .media-box.gemstone-gallery {
    ul {
      li {
        width: percentage((1/4));
      }
    }
  }
}
@media screen and (min-width: 1281px) {
  .media-box.gemstone-gallery {
    ul {
      li {
        width: percentage((1/5));
      }
    }
  }
}

// Gemcare
.gemcare-table-nav {
  display: block;
  position: sticky;
  top: -2px;
  z-index: 10;
  padding: 0 0 60px;
  text-align: center;
  &.is-sticked {
    ul {
      flex-wrap: nowrap;
      overflow: auto;
      background-color: @white;
      box-shadow: 0px 3px 6px rgba(0,0,0,0.5);
      li {
        &:first-child {
          border-left: none;
        }
        &:last-child {
          border-right: none;
        }
      }
    }
  }
  ul {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    li {
      flex: 0 0 40px;
      margin: 0;
      line-height: 20px;
      border-left: 1px solid @mediumgrey;
      a {
        display: block;
        .sans-serif-bold();
      }
    }
  }
}
@media screen and (min-width: 768px){
  .gemcare-table-nav {
    padding: 0;
    ul {
      position: relative;
      li {
        flex: 1 0 auto;
        &:last-child {
          border-right: 1px solid @mediumgrey;
        }
      }
    }
  }
}
#treatment h2 {
  margin: 0;
  padding: 50px 10px 0;
}
@media screen and (min-width: 768px) {
  #treatment h2:first-child {
    padding: 20px 10px 0;
  }
}
.gem-care-table {
  display: block;
  margin: 0 0 20px;
  overflow: auto;
  h3 {
    background-color: @lightgrey;
    margin: 0;
    padding: 10px;
    font-size: 15px;
    line-height: 18px;
    .gem-varieties {
      .sans-serif-regular();
      font-size: 12px;
    }
  }
  .table {
    width: 100%;
    overflow: auto;
    border-top: 1px solid #dcdad9;
    &:first-child {
      border-top: none;
    }
    &:after {
      width: 100%;
      clear: both;
      font-size: 0;
      content: ' ';
    }
    .tr {
      width: 50%;
      float: left;
      overflow: auto;
    }
    .th, .td {
      box-sizing: border-box;
      overflow: auto;
      float: left;
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      text-align: left;
    }
    .th {
      user-select: none;
    }
    .ja:before,
    .nein:before {
      display: inline-block;
      margin-right: 5px;
      font-family: @icon;
      font-size: 14px;
    }
    .ja:before {
      color: @green;
      content: '\e902';
    }
    .nein:before {
      color: @red;
      content: '\e901';
    }
  }
}
@media screen and (min-width: 1025px) {
  .gem-care-table {
    .table {
      width: percentage((1/5));
      float: left;
      overflow: auto;
      border-top: 1px solid #dcdad9;
      &:after {
        width: 100%;
        clear: both;
        font-size: 0;
        content: ' ';
      }
      &:first-child {
        border-top: none;
      }
      .tr {
        width: 50%;
        float: left;
        overflow: auto;
      }
      .th,
      .td {
        overflow: auto;
      }
    }
  }
}
ul.f-left.level2.steine {
  width: 100%;
}


.page-gemstone {
  .main.max-width {
    padding: 0;
  }
  .page-header {
    padding: 5px 5px 0;
    h1 {
      padding: 0;
    }
    h4 {
      padding-top: 15px;
    }
    .block-gemstone-image {
      overflow: hidden;
      width: 100%;
      margin: 0 0 5px;
      padding-bottom: percentage((2/3));
      .gemstone-page-title-image {
        position: absolute;
        top: 0;
        left: 5px;
        right: 5px;
        img {
          position: relative;
          width: 100%;
          top: -20%;
          left: 0;
        }
      }
    }
    .block-pagenav {
      h4 {
        margin: 15px 0 0;
      }
      .block-title {
        padding: 0;
      }
      .table-of-contents {
        margin: 10px 15px;
        li {
          margin: 0 0 5px;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
  .page-content {
    .cms-row {
      margin: 0 0 10px;
      .cms-col.col-narrow {
        width: 100%;
        padding: 0 5px;
        float: left;
      }
      .cms-col.col-wide {
        width: 100%;
        padding: 0 5px;
        float: left;
        h2 {
          width: 100%;
          margin: 20px 0 10px;
          clear: both;
        }
        h2:first-child {
          margin: 0 0 10px;
        }
        p {
          margin: 0 0 10px;
        }
      }
    }
    .infobox-gem {
      background: @lightgrey;
      .sans-serif-bold();
      margin: 0 0 20px 0;
      .shadow();
      &-head {
        font-size: 15px;
      }
      th {
        padding: 5px 10px 0;
        background: #dedede;
        font-size: 13px;
        line-height: 30px;
        text-align: left;
        border-bottom: 1px solid @mediumgrey;
      }
      td {
        width: 50%;
        padding: 7px 10px 5px;
        font-size: 12px;
        line-height: 18px;
        border-bottom: 1px solid @mediumgrey;
        &:first-child {
          .sans-serif-regular();
        }
        &:nth-child(2) {
          .sans-serif-light();
        }
        img {
          margin: 3px auto 5px;
          .shadow();
        }
      }
      sub {
        font-size: 60%;
      }
    }
  }
  .variety-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    li {
      display: inline-block;
      flex-basis: percentage((1/3));
      text-align: center;
    }
  }
}
@media screen and (min-width: 480px) {
  .page-gemstone {
    .variety-images {
      li {
        flex-basis: 20%;
      }
    }
  }
}
@media screen and (min-width: 640px) {
  .page-gemstone {
    .page-header {
      .block-gemstone-image {
        width: 50%;
        margin: 5px 0 0;
        padding-bottom: 50%;
        float: right;
        .gemstone-page-title-image {
          img {
            top: 0;
          }
        }
      }
      .block-pagenav {
        width: 50%;
        float: left;
      }
    }
    .page-content {
      .cms-row {
        .cms-col.col-wide {
          width: percentage((2/3));
        }
        .cms-col.col-narrow {
          width: percentage((1/3));
        }
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .page-gemstone {
    .page-header {
      .block-gemstone-image {
        padding: 0 10px 50%;
        .gemstone-page-title-image {
          left: 10px;
          right: 10px;
        }
      }
      .block-pagenav {
        padding: 5px 10px 10px;
      }
    }
    .page-content {
      .cms-row {
        .cms-col.col-wide {
          width: 100%;
          padding: 0 10px;
          float: none;
        }
        .cms-col.col-narrow {
          padding: 0 10px;
        }
      }
      .infobox-gem {
        th {
          padding: 5px 20px 0;
        }
        td {
          padding: 7px 20px 5px;
          img {
            margin: 13px auto 15px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 800px) {
  .page-gemstone {
    .page-header {
      margin: 10px 0 30px;
      .block-gemstone-image {
        width: percentage((2/3));
        padding-bottom:0;
        .gemstone-page-title-image {
          position: relative;
          left: 0;
          right: 0;
          width: 100%;
          box-sizing: border-box;
          padding: 10px;
          overflow: hidden;
          img {
            width: auto !important;
            max-width: none !important;
            position: relative;
            top: 50%;
            left: 50%;
            margin-top: -50%;
            margin-left: -50%;
          }
        }
      }
      .block-pagenav {
        width: percentage((1/3));
      }
    }
    .page-content {
      .cms-row {
        .cms-col.col-narrow {
          width: percentage((1/3));
        }
        .cms-col.col-wide {
          width: percentage((2/3));
          float: left;
        }
      }
    }
  }
}

/* juwelo.fr guide-des-pierres */
.cms-guide-des-pierres-pierres-anniversaire-mariage td{
  vertical-align:middle;
}

.cms-page-view.cms-guide-des-pierres-rubellite .infobox-gem th,
.cms-page-view.cms-guide-des-pierres-rubellite .infobox-gem td {
  padding: 5px;
  vertical-align: top;
}
.cms-page-view.cms-guide-des-pierres-rubellite .infobox-gem tr {
  border: 1px solid;
}
.cms-page-view.cms-guide-des-pierres-rubellite .infobox-gem table {
  border-collapse: separate; border-spacing: 5px;
  margin: 0 auto;
}
.cms-guide-des-pierres-pierres-precieuses-et-astrologie img,
.cms-guide-des-pierres-astrologie-chinoise-des-pierres-precieuses img,
.cms-edelsteinlexikon-edelsteine-astrologie img {
  display: inline-block;
}
@media screen and (max-width: 600px) {
  .cms-guide-des-pierres-couleurs-des-pierres .popup-wrapper {
    left:0;
  }
  .cms-guide-des-pierres-couleurs-des-pierres .popup-content img.f-left {
    display:none;
  }
}
/* juwelo.fr couler-seite popup-width-fix */
@media screen and (min-width: 767px) {
  .cms-guide-des-pierres-couleurs-des-pierres .popup-content {
    max-width: 840px;
  }
}


// Auction History
.auctions {
  .cart-progress {
    .css3-loader {
      width: 50px;
      height: 50px;
      background-image: url('../images/loader.gif');
    }
  }
  .page-title {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: space-between;
    h1 {
      margin: 0 15px 15px 0;
      flex: 0 1 auto;
      font-size: 20px;
      line-height: 25px;
      text-align: left;
      text-transform: none;
    }
    .date-selector {
      flex: 0 0 auto;
      position: relative;
      z-index: 99;
      margin: 0 0 10px;
      height: 40px;
      .label {
        font-size: 0;
        &:before {
          float: left;
          font-family: @icon;
          font-size: 16px;
          line-height: 40px;
          content: '\f073';
        }
      }
      .select-box-custom {
        display: inline-block;
        #date_selected {
          display: block;
          box-sizing: border-box;
          padding: 3px 10px 1px;
          border: 1px solid @mediumgrey;
          border-radius: 3px;
          background: @white;
          font-size: 12px;
          line-height: 31px;
          cursor: pointer;
          &:after {
            display: inline-block;
            width: 20px;
            float: right;
            box-sizing: border-box;
            font-family: @icon;
            text-align: right;
            content: ' \f0dc';
          }
        }
        #date_select {
          position: relative;
          max-height: 0;
          overflow: hidden;
          box-sizing: border-box;
          border: 1px solid @mediumgrey;
          border-bottom-right-radius: 3px;
          border-bottom-left-radius: 3px;
          border-top: none;
          background: @white;
          font-size: 12px;
          margin-top: -1px;
          &.select-open {
            max-height: 300px;
          }
          li {
            margin: 0;
            a {
              display: block;
              padding: 3px 30px 1px 10px;
              border-top: 1px solid @mediumgrey;
              line-height: 36px;
              text-decoration: none;
              white-space: nowrap;
              color: @darkgrey;
              &:hover {
                background: @lightgrey;
                color: @pink;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 400px){
  .auctions {
    .page-title {
      h1 {
        flex: 1 0 auto;
      }
    }
  }
}
@media screen and (min-width: 768px){
  .auctions {
    .page-title {
      h1 {}
      .date-selector {
        margin: 0 10px 20px 20px;
      }
    }
  }
}
@media screen and (min-width: 1024px){
  .auctions {
    .page-title {
      h1 {
        flex: 1 0 auto;
      }
      .date-selector {
        margin: 0 0 20px 20px;
        .label {
          display: none;
        }
        .select-box-custom {
          #date_selected {
            display: none;
          }
          #date_select {
            position: relative;
            display: flex;
            margin-top: 0;
            width: auto;
            max-height: none;
            padding-top: 0;
            border: none;
            overflow: hidden;
            li {
              flex: 0 0 auto;
              margin: 0;
              a {
                display: block;
                margin-left: 1px;
                padding: 3px 10px 1px;
                .sans-serif-regular();
                line-height: 25px;
                border: 1px solid @pink;
                background-color: @pink;
                color: @white;
                &.current-day {
                  border-color: @mediumgrey;
                  background-color: @white;
                  color: @darkgrey;
                }
              }
              &:first-child {
                a {
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                }
              }
              &:last-child {
                a {
                  border-top-right-radius: 5px;
                  border-bottom-right-radius: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.auction-history {
  .presenter {
    .image {
      position: relative;
      z-index: 2;
      display: block;
      width: 30px;
      height: 30px;
      margin: 0 auto;
      border: 1px solid @mediumgrey;
      .rounded();
      background-image: url('../images/presenters_de.jpg');
      background-size: 100%;
      background-position-x: 0;
      &.rebecca-achenbach { background-position-y: 0; }
      &.arion-ahrens { background-position-y: -100%; }
      &.claudia-campus { background-position-y: -200%; }
      &.nicole-driebe { background-position-y: -300%; }
      &.mirko-von-matthey { background-position-y: -400%; }
      &.maren-gilzer { background-position-y: -500%; }
      &.adrian-marton { background-position-y: -600%; }
      &.caterina-pogorzelski { background-position-y: -700%; }
      &.jeannette-sachse { background-position-y: -800%; }
      &.monique-stegemann { background-position-y: -900%; }
      &.judith-wegner { background-position-y: -1000%; }
      &.silvana-westphal { background-position-y: -1100%; }
      &.irene-woike { background-position-y: -1200%; }
      &.sebastian-wolf { background-position-y: -1300%; }
      &.bianca-zoch { background-position-y: -1400%; }
      &.anna-katharina-stoll { background-position-y: -1500%; }
      &.diana-rudolf { background-position-y: -1600%; }
      &.kate-mitchell { background-position-y: -1700%; }
      &.lisa-putz { background-position-y: -1800%; }
      &.mirya-kalmuth { background-position-y: -1900%; }
      &.beatrice-merolla { background-position-y: -2000%; }
      &.daniele-quagliaroli { background-position-y: -2100%; }
      &.elisabetta-becattini { background-position-y: -2200%; }
      &.federico-pavani { background-position-y: -2300%; }
      &.marina-de-luca { background-position-y: -2400%; }
      &.norman-soltan { background-position-y: -2500%; }
    }
  }
  .past-auctions-products-grid-header {
    position: relative;
    display: block;
    height: 32px;
    padding: 0 5px;
    margin: 20px 0;
    clear: both;
    h3 {
      margin: 0;
      padding: 7px 0 0 50px;
      background-color: @lightgrey;
      border-bottom: 1px solid @darkgrey;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      .sans-serif-bold();
      font-size: 12px;
      line-height: 13px;
    }
    .presenter {
      display: block;
      padding: 0 0 0 50px;
      background-color: @lightgrey;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      .sans-serif-light();
      font-size: 12px;
      line-height: 21px;
      .image {
        position: absolute;
        left: 15px;
        top: 5px;
        border-color: @darkgrey;
      }
    }
  }
  .past-auctions-products-grid {
    width: 100%;
    margin: 0 0 40px;
    overflow: hidden;
    &-title {
      display: block;
      margin: 0 5px;
      padding: 5px 10px;
      line-height: 20px;
      text-align: left;
      color: @black;
      background-color: @lightgrey;
      cursor: pointer;
      &:after {
        font-family: @icon;
        content: '\f078';
        float: right;
      }
      &.list-open {
        &:after {
          content: '\f077';
        }
      }
    }
    &-item {
      position: relative;
      display: block;
      float: left;
      box-sizing: border-box;
      width: 50%;
      padding: 0 5px;
      margin: 10px 0;
      text-align: center;
      .item-wrapper {
        position: relative;
        width: 100%;
        padding-bottom: calc(100% + 140px);
        cursor: pointer;
        &:before {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 3;
          background-color: rgba(255,255,255,0.5);
          font-family: @icon;
          font-size: 10px;
          line-height: 100px;
          text-align: center;
          color: @pink;
          content: '\f004';
        }
        &.adding-to-wishlist {
          &:before {
            display: block;
            font-size: 48px;
            transition: font-size 0.2s;
          }
        }
        .link-image {
          position: absolute;
          top: 10px;
          left: 0;
          width: 100%;
          display: block;
          .brand {
            position: absolute;
            top: -1px;
            left: -1px;
            z-index: 3;
            text-align: left;
            img {
              margin: 0;
              width: auto;
              max-width: 40px;
            }
          }
          .auction-product-image {
            width: 150px;
            max-width: 100%;
            margin: 0 auto;
          }
        }
        .link-name {
          position: absolute;
          left: 0;
          bottom: 60px;
          width: 100%;
          .auction-product-name {
            display: block;
            line-height: 20px;
            color: #111111;
          }
        }
        .link-price {
          position: absolute;
          left: 0;
          bottom: 30px;
          width: 100%;
          .price {
            display: block;
            .sans-serif-bold();
            font-size: 12px;
            line-height: 30px;
            text-transform: uppercase;
            color: #111111;
          }
        }
        .product-book, .sold-out {
          position: absolute;
          bottom: 0;
          display: block;
          width: 100%;
          box-sizing: border-box;
          .sans-serif-regular();
          line-height: 25px;
          white-space: nowrap;
          background-color: @pink;
        }
        .product-book {
          background-color: @pink;
          cursor: pointer;
          color: @white;
        }
        .sold-out {
          background-color: @lightgrey;
        }
        .product-wishlist {
          position: absolute;
          left: 50%;
          bottom: 36px;
          width: 50%;
          height: 15px;
          display: block;
          box-sizing: border-box;
          line-height: 15px;
          color: @pink;
          cursor: pointer;
          white-space: nowrap;
          .wishlist-icon {
            display: inline;
            padding-right: 5px;
            &:before {
              display: inline;
              font-size: 10px;
              line-height: 15px;
            }
          }
        }
        .link-showtime-sku {
          position: absolute;
          right: 0;
          top: 0;
          .auction-product-showtime {
            display: inline-block;
            height: 18px;
            padding: 0 5px;
            font-size: 11px;
            line-height: 20px;
            color: @darkgrey;
            background-color: @lightgrey;
            border-radius: 10px;
            &:before {
              display: inline;
              padding-right: 3px;
              float: left;
              font-family: @icon;
              font-size: 13px;
              line-height: 18px;
              content: '\f017';
            }
          }
          .auction-product-sku {
            display: none;
          }
        }
      }
      &.is-on-wishlist .product-wishlist {
        .wishlist-icon {
          &:before {
            content: '\f004';
          }
        }
      }
    }
  }
}
.no-touchevents {
  .auction-history {
    .past-auctions-products-grid {
      &-item:hover {
        .item-wrapper {
          box-shadow: 0px 1px 3px @mediumgrey;
        }
      }
    }
  }
}
@media screen and (min-width: 360px) {
  .auction-history {
    .past-auctions-products-grid {
      &-item {
        .item-wrapper {
          padding-bottom: calc(100% + 120px);
        }
      }
    }
  }
}
@media screen and (min-width: 401px) {
  .auction-history {
    .past-auctions-products-grid {
      &-item {
        .item-wrapper {
          padding-bottom: 250px;
          .link-showtime-sku {
            .auction-product-sku {
              .label {
                display: inline;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 641px){
  .auction-history {
    .past-auctions-products-grid {
      &-item {
        width: percentage((1/3));
        .item-wrapper {
          padding-bottom: 270px;
        }
      }
    }
  }
}
@media screen and (min-width: 768px){
  .auction-history {
    .date-selector {
      margin: 0 10px 20px;
    }
    .past-auctions-products-grid-header {
      padding: 0 10px;
    }
    .past-auctions-products-grid {
      margin: 0 0 60px;
      &-title {
        margin: 0 10px;
      }
      &-item {
        padding: 0 10px;
      }
    }
  }
}
@media screen and (min-width: 841px) {
  .auction-history {
    .past-auctions-products-grid {
      &-item {
        width: percentage((1/4));
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .auction-history {
    .past-auctions-products-grid {
      &-item {
        width: percentage((1/5));
      }
    }
  }
}


// Auction History Timeline
.auction-history-timeline {
  position: relative;
  top: 0;
  margin: 0 5px 20px;
  padding: 5px 20px;
  border-top: 1px solid @mediumgrey;
  border-bottom: 1px solid @mediumgrey;
  background-color: @white;
  transition: top .75s;
  &.sticked {
    position: fixed;
    z-index: 9999;
    top: 0;
    box-shadow: 0px 2px 2px rgba(0,0,0,0.5);
  }
  .slider-control {
    background-color: @pink;
    width: 15px;
    height: 100%;
    top: 0;
    bottom: 0;
    margin: 0;
    line-height: 64px;
    color: @white;
    opacity: 1;
    &:before,
    &:after {
      top: 24px;
    }
    &.next {
      right: 0;
      &:before {
        left: -2px;
      }
      &:after {
        left: -4px;
      }
    }
    &.prev {
      left: 0;
      &:before {
        left: 6px;
      }
      &:after {
        left: 4px;
      }
    }
  }
  .timeline-item {
    display: inline-block;
    width: percentage((1/18));
    min-width: 40px;
    box-sizing: border-box;
    cursor: pointer;
    .hour {
      display: block;
      margin: 0 1px 5px;
      border-bottom: 2px solid @mediumgrey;
      .sans-serif-regular();
      font-size: 10px;
      line-height: 15px;
      text-align: left;
    }
    .presenter {
      position: relative;
      display: block;
      text-align: center;
      &:before {
        position: absolute;
        left: 0;
        right: 50%;
        display: block;
        height: 15px;
        border-bottom: 2px solid @mediumgrey;
        content: ' ';
      }
      &.no-image {
        &:before {
          left: 0;
          right: 0;
        }
        .image {
          border: none;
          background-image: none;
        }
      }
    }
    &.upcoming {
      cursor: default;
      .hour {
        color: @mediumgrey;
      }
      .presenter:before {
        display: none;
      }
    }
    &.active {
      .hour {
        border-color: @pink;
      }
      .presenter {
        &:before {
          border-color: @pink;
        }
        .image {
          border-color: @pink;
        }
      }
    }
  }
}
@media screen and (min-width: 769px){
  .auction-history-timeline {
    margin: 0 10px 20px;
    .timeline-item {
      .hour {
        font-size: 12px;
      }
    }
  }
}


// Auction History Product Details
.auction-product {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background-color: rgba(0,0,0,0.3);
  .back {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    left: 10px;
    height: 40px;
    padding: 0 45px 0 0;
    background-color: @white;
    border-bottom: 1px solid @mediumgrey;
    text-align: right;
    line-height: 40px;
    &:before {
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 2;
      display: block;
      width: 30px;
      height: 30px;
      color: @white;
      background-color: @pink;
      cursor: pointer;
      text-align: center;
      font-family: @icon;
      content: '\e901';
      font-size: 20px;
      line-height: 30px;
    }
  }
  .auction-product-view {
    position: absolute;
    top: 40px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    background-color: @white;
    overflow: auto;
    .shadow();
    .back {
      display: none;
    }
  }
  .auction-product-media {
    margin: 0 auto;
    width: 100%;
    clear: both;
    .auction-product-image {
      width: 100%;
      max-width: 300px;
      height: auto;
      margin: 0 auto;
    }
    .auction-product-brand {
      position: absolute;
      top: 40px;
      left: 10px;
      z-index: 2;
      max-width: 75px;
    }
    .auction-product-show {
      h3 {
        margin: 0;
        .sans-serif-regular();
        font-size: 9px;
        line-height: 10px;
      }
      p {
        margin: 0;
        padding: 4px 0;
        font-size: 10px;
        line-height: 12px;
      }
      .presenter {
        position: relative;
        display: block;
        margin: 0 10px 0 0;
        float: left;
        .image {
          position: relative;
          z-index: 2;
          display: block;
          width: 30px;
          height: 30px;
          border: 1px solid @mediumgrey;
          .rounded();
          background-image: url('../images/presenters_de.jpg');
          background-size: 100%;
          background-position-x: 0;
        }
        #rebecca-achenbach { background-position-y: 0; }
        #arion-ahrens { background-position-y: -100%; }
        #claudia-campus { background-position-y: -200%; }
        #nicole-driebe { background-position-y: -300%; }
        #mirko-von-matthey { background-position-y: -400%; }
        #maren-gilzer { background-position-y: -500%; }
        #adrian-marton { background-position-y: -600%; }
        #caterina-pogorzelski { background-position-y: -700%; }
        #jeannette-sachse { background-position-y: -800%; }
        #monique-stegemann { background-position-y: -900%; }
        #judith-wegner { background-position-y: -1000%; }
        #silvana-westphal { background-position-y: -1100%; }
        #irene-woike { background-position-y: -1200%; }
        #sebastian-wolf { background-position-y: -1300%; }
        #bianca-zoch { background-position-y: -1400%; }
        #anna-katharina-stoll { background-position-y: -1500%; }
        #diana-rudolf { background-position-y: -1600%; }
        #kate-mitchell { background-position-y: -1700%; }
        #lisa-putz { background-position-y: -1800%; }
        #mirya-kalmuth { background-position-y: -1900%; }
        #beatrice-merolla { background-position-y: -2000%; }
        #daniele-quagliaroli { background-position-y: -2100%; }
        #elisabetta-becattini { background-position-y: -2200%; }
        #federico-pavani { background-position-y: -2300%; }
        #marina-de-luca { background-position-y: -2400%; }
        #norman-soltan { background-position-y: -2500%; }
        &.no-image {
          .image {
            background-image: none;
          }
        }
       }
    }
  }
  .auction-product-shop {
    box-sizing: border-box;
    padding: 20px 10px 0;
    width: 100%;
    clear: both;
    .auction-product-name {
      margin: 0;
      font-family: @serif-normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      text-transform: none;
    }
    .auction-product-sku {
      font-size: 11px;
      line-height: 20px;
      text-align: center;
    }
    .auction-product-price {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      font-size: 0;
      line-height: 0;
      .label {
        display: block;
        margin: 0 0 5px;
        font-size: 10px;
        line-height: 15px;
      }
      .price {
        display: block;
        font-size: 28px;
        line-height: 30px;
      }
    }
    .price-hint {
      font-size: 10px;
      line-height: 20px;
      margin: 5px 0 20px;
    }
    .product-form {
      margin: 0 0 20px;
      .auction-product-variants {
        box-sizing: border-box;
        margin: 0 0 10px;
        .select-box {
          box-sizing: border-box;
          &:after {
            top: 14px;
          }
          select {
            line-height: 30px;
          }
        }
        .last-variation {
          font-size: 12px;
          line-height: 15px;
          text-align: center;
        }
      }
      .auction-product-button {
        display: block;
        box-sizing: border-box;
        margin: 0;
        .btn {
          display: block;
          margin: 0;
          padding: 12px 10px 10px;
        }
      }
      .auction-product-bid-feedback {
        h2 {
          position: relative;
          margin: 0 0 10px;
          padding: 0 0 0 20px;
          font-size: 14px;
          line-height: 20px;
          text-transform: none;
        }
        p {
          margin: 0 0 20px;
          font-size: 11px;
        }
      }
    }
    &.no-shadow {
      .product-form {
        padding: 0;
        .no-shadow();
        .auction-product-variants {
          display: none;
        }
        .auction-product-button {
          margin: 0 0 30px;
        }
      }
    }
    .auction-product-details {
      display: block;
      box-sizing: border-box;
      width: 100%;
      margin: 10px 0;
      h3 {
        margin: 0;
        .sans-serif-regular();
        font-size: 12px;
        line-height: 30px;
        text-transform: uppercase;
      }
      ul {
        display: block;
        box-sizing: border-box;
        width: 100%;
        margin: 10px 0;
        padding: 0;
        li {
          margin: 0;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          padding: 0;
          &:nth-child(even){
            padding-bottom: 10px;
            margin: 0 0 10px;
            border-bottom: 1px solid @lightgrey;
          }
        }
      }
    }
  }

  // states

  .auction-product-bid-processing,
  .auction-product-bid-failed,
  .auction-product-bid-confirmed,
  .auction-product-bid-purchased {
    display:none;
  }

  &.state-processing {
    .auction-product-bid-ready {
      display:none;
    }
    .auction-product-bid-processing {
      display:block;
      h2 {
        color: orange;
        &:before {
          position: absolute;
          top: 4px;
          left: 2px;
          display: block;
          width: 0;
          height: 0;
          border-top: 5px solid orange;
          border-bottom: 5px solid orange;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          content: ' ';
        }
      }
    }
  }

  &.state-failed {
    .auction-product-bid-ready {
      display:none;
    }
    .auction-product-bid-failed {
      display:block;
      h2 {
        .crossmarkred();
      }
    }
  }

  &.state-confirmed {
    .auction-product-bid-ready {
      display:none;
    }
    .auction-product-bid-confirmed {
      display:block;
      h2 {
        color: @green;
        .checkmarkgreen();
      }
    }
  }
  
  &.state-purchased {
    .auction-product-bid-purchased {
      display:block;
      h2 {
        color: @green;
        .checkmarkgreen();
      }
    }
  }
}
.product-error-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background-color: rgba(0,0,0,0.3);
  .auction-product-error {
    position: relative;
    max-width: 500px;
    margin: 60px auto;
    padding: 40px 10px 20px;
    background-color: @white;
    h2 {
      margin: 40px 0 20px;
    }
  }
  .back {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    left: 10px;
    height: 40px;
    padding: 0 45px 0 0;
    background-color: @white;
    border-bottom: 1px solid @mediumgrey;
    text-align: right;
    line-height: 30px;
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      display: block;
      width: 30px;
      height: 30px;
      color: @white;
      background-color: @pink;
      cursor: pointer;
      text-align: center;
      font-family: @icon;
      content: '\e901';
      font-size: 20px;
      line-height: 30px;
    }
  }
}
@media screen and (min-width: 768px){
  .auction-product {
    .back {
      display: none;
    }
    .auction-product-view {
      position: relative;
      top: 0;
      left: 0;
      right: auto;
      bottom: auto;
      box-sizing: border-box;
      margin: 0 20px;
      padding: 40px 20px 20px;
      background-color: @white;
      .shadow();
      .back {
        display: block;
        top: 0;
        right: 0;
        left: 0;
        border-bottom: none;
      }
    }
    .auction-product-media {
      box-sizing: border-box;
      width: 50%;
      padding: 0 10px 0 0;
      float: left;
      clear: none;
      .auction-product-image {
        max-width: 400px;
      }
      .auction-product-brand {
        top: 20px;
        left: 20px;
      }
      .auction-product-show {
        position: absolute;
        bottom: 20px;
        left: 20px;
        width: 100%;
        max-width: 400px;
        z-index: 2;
      }
    }
    .auction-product-shop {
      width: 50%;
      padding: 0 0 0 10px;
      float: right;
      clear: none;
      .auction-product-name {
        text-align: left;
      }
      .auction-product-sku {
        text-align: left;
      }
      .auction-product-price {
        text-align: left;
      }
      .product-form {
        .auction-product-variants {
          .last-variation {
            text-align: left;
          }
        }
      }
      .auction-product-details {
        ul {
          max-height: 300px;
          overflow-y: auto;
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .auction-product {
    bottom: auto;
    .auction-product-view {
      max-width: 1000px;
      .shadow();
      background-color: @white;
    }
  }
}
@media screen and (min-width: 1040px) {
  .auction-product {
    .auction-product-view {
      margin: 0 auto;
    }
  }
}



// Kollektionen
.cms-juwelo-kollektionen,
.cms-juwelo-collezioni,
.cms-collections,
.cms-juwelo-collections,
.cms-juwelo-colecciones,
.cms-juwelo-collecties {
  h1 {
    margin: 15px 0;
  }
  .page-content {
    .col {
      width: 100%;
      float: left;
      margin: 0 0 10px;
      a {
        display: block;
        position: relative;
        padding-top: 58.571431%;
        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
        .x-adaptive-image {
          position: relative;
        }
      }
      &.inline-links {
        max-width: 1108px;
        margin: 40px auto;
        float: none;
        text-align: center;
        h2 {
          text-transform: none;
          padding: 10px 0;
        }
        a {
          display: inline-block;
          box-sizing: border-box;
          width: 100%;
          padding: 10px 5px;
          margin: 8px 4px;
          border: 1px solid @darkgrey;
          color: @darkgrey;
        }
      }
    }
  }
}
@media screen and (min-width: 481px) {
  .cms-juwelo-kollektionen,
  .cms-juwelo-collezioni,
  .cms-collections,
  .cms-juwelo-collections,
  .cms-juwelo-colecciones,
  .cms-juwelo-collecties {
    .page-content {
      .col {
        &.inline-links {
          a {
            width: calc(50% - 8px);
            float: left;
            margin: 4px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 640px) {
  .cms-juwelo-kollektionen,
  .cms-juwelo-collezioni,
  .cms-collections,
  .cms-juwelo-collections,
  .cms-juwelo-colecciones,
  .cms-juwelo-collecties {
    .page-content {
      .col {
        &.inline-links {
          padding: 0 40px;
          a {
            width: calc(50% - 8px);
            float: left;
            margin: 4px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .cms-juwelo-kollektionen,
  .cms-juwelo-collezioni,
  .cms-collections,
  .cms-juwelo-collections,
  .cms-juwelo-colecciones,
  .cms-juwelo-collecties {
    .page-content {
      .col {
        &.inline-links {
          a {
            width: calc(33% - 16px);
            margin: 8px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1025px) {
  .cms-juwelo-kollektionen,
  .cms-juwelo-collezioni,
  .cms-collections,
  .cms-juwelo-collections,
  .cms-juwelo-colecciones,
  .cms-juwelo-collecties {
    .page-content {
      .col {
        &.inline-links {
          a {
            width: calc(25% - 16px);
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .cms-juwelo-kollektionen,
  .cms-juwelo-collezioni,
  .cms-collections,
  .cms-juwelo-collections,
  .cms-juwelo-colecciones,
  .cms-juwelo-collecties {
    .page-content {
      .col {
        &.inline-links {
          padding: 0 8px;
        }
      }
    }
  }
}
// Adventskalender 2
#adventskalender {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  overflow: visible;
  .adventskalender-motiv {
    position: relative;
  }
  .adventskalender-day {
    display: block;
    box-sizing: border-box;
    position: relative;
    width: percentage((1/4));
    padding-bottom: percentage((1/4));
    float: left;
    .adventskalender-day-content {
      position: absolute;
      top: 2.5%;
      left: 2.5%;
      bottom: 2.5%;
      width: 92.5%;
      z-index: 1;
      p {
        font-size: 0;
        line-height: 0;
      }
      a {
        display: none;
      }
    }
    .adventskalender-day-opener {
      position: absolute;
      top: 2.5%;
      left: 2.5%;
      bottom: 2.5%;
      width: 92.5%;
      z-index: 2;
  }
    &.current{
      .adventskalender-day-opener {
        border: 2px dashed rgba(195,157,111,0.3);
      }
      .day-image {
        display: none;
      }
      .placeholder {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: rgba(255,255,255,0.5);
      }
    }
    &.upcoming {
      .adventskalender-day-opener {
        border: 2px dashed rgba(195,157,111,0.2);
      }
    }
    &.passed {
      .day-image {
        display: block;
      }
      .adventskalender-day-content {
        border: 2px dashed rgba(177,149,116,0.2);
      }
      .adventskalender-day-opener {
        display: none;
      }
      .placeholder {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: rgba(255,255,255,0.5);
      }
    }
  }
  .adventskalender-msg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1001;
    background-color: rgba(255,255,255,0.5);
    text-align: center;
    display: none;
    .msg-content {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      max-width: 300px;
      margin: 0 auto 40px;
      padding: 20px 10px;
      background-color: @white;
      .day-image {
        position: relative;
        z-index: 5;
        width: 90%;
        margin: 10px auto 20px;
        background-color: rgba(255,255,255,0.5);
      }
      h2 {
        margin: 20px 20px 0;
      }
      .day-title {
        margin: 0 15px 0;
      }
      .day-text {
        margin: 15px;
      }
      .day-code-label {
        margin: 15px 15px 0;
      }
      .day-code {
        display: inline-block;
        margin: 10px 0;
        .sans-serif-bold();
        font-size: 18px;
        border: 2px solid black;
        padding: 10px 20px 6px;
      }
      .day-link {
        display: block;
        margin: 15px;
      }
      .day-conditions {
        margin: 15px 15px 0;
      }
    }
    .close-msg {
      position: absolute;
      top: -10px;
      right: -10px;
      display: block;
      width: 30px;
      height: 30px;
      .rounded();
      border: 2px solid @white;
      background-color: @pink;
      .sans-serif-bold();
      font-size: 12px;
      line-height: 32px;
      text-align: center;
      color: @white;
      cursor: pointer;
    }
  }
}
@media screen and (min-width: 790px) {
  #adventskalender {
    .adventskalender-day {
      width: percentage((1/6));
      padding-bottom: percentage((1/6));
    }
  }
}


// 404
.cms-no-route {
  h1 {
    margin: 30px 0;
  }
  #search_form {
    display: block;
    margin: 20px 0 40px;
  }
  #search_input {
    margin: 10px auto;
    max-width: 360px;
  }
}


// Quiz
.quiz-container {
  &.cms-row {
    padding: 5px 0 15px;
    h2 {
      padding: 1em 0 0.5em;
      font-size: 18px;
    }
  }
  .answer-box {
    padding: 13px;
    margin-top: -1px;
    font-size: 16px;
    height: 30%;
    border: 1px solid @mediumgrey;
    cursor: pointer;
    label {
      cursor: pointer;
    }
    &.correct {
      background: lightgreen;
    }
    &.wrong {
      background: lightcoral;
    }
  }
}
@media (min-width: 800px) {
  .quiz-container {
    &.cms-row {
      h2 {
        padding: 0;
        margin-top: 0;
      }
    }
  }
}

// Raffle
.raffle {
  max-width: 500px;
  margin: 0 auto;
  float: none !important;
  h2 {
    margin: 20px auto !important;
    text-align: center;
    text-transform: none;
  }
  h3 {
    line-height: 150%;
    margin: 20px 0 !important;
    text-align: center;
  }
  ol {
    margin: 0 0 20px;
    padding-left: 10px;
  }
  p,
  li {
    font-size: 12px;
    line-height: 150%;
    margin: 0 0 20px;
  }
  .dynamic-label {
    margin: 30px auto;
    .input-box {
      margin: 0 0 5px;
      &.checkbox {
        margin: 5px auto 20px;
        checkbox {
          border-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
  .buttons-set {
    margin: 20px 0 10px;
    #raffle-submit {
      width: 50%;
      margin: 0 auto;
    }
  }
  #raffle-message {
    font-size:14px;
    line-height:30px;
    padding:0 10px;
    border:1px solid @black;
    margin:10px 0;
    &.success {
      color: @green;
      border-color: @green;
    }
    &.invalid,&.error {
      color: @red;
      border-color:@red;
    }
  }
}


/* an-ihrer-seite unterschrit size */
.cms-an-ihrer-seite #unterschrift-annette {
  margin: -1em 0em 1em 0em;
  width: 50%;
}
@media screen and (min-width: 767px) {
  .cms-an-ihrer-seite #unterschrift-annette {
    margin: -1em 0em 1em 0em;
    width: 20%;
  }
}


.services-table {
  width: 100%;
  font-size: 12px;
  line-height: 20px;
  .tr {
    display: block;
    margin: 0 0 40px;
    border: 1px solid @lightgrey;
    &:first-child {
      display: none;
    }
    .th {
      display: none;
      padding: 10px;
      .sans-serif-bold();
      text-align: left;
      background-color: @lightgrey;
    }
    .td {
      display: block;
      padding: 10px;
      border-top: 1px solid @lightgrey;
      &:first-child {
        border-top: none;
        .sans-serif-regular();
      }
    }
  }
  .label {
    display: inline-block;
    width: percentage((1/3));
    .sans-serif-bold();
    &:after {
      content: ':';
    }
  }
}
@media screen and (min-width: 640px){
  .services-table {
    display: table;
    .tr {
      display: table-row;
      margin: 0;
      border: none;
      &:first-child {
        display: table-row;
      }
      .th, .td {
        display: table-cell;
        padding: 10px;
      }
      .td {
        border-top: 1px solid @lightgrey;
        &:first-child {
          border-top: 1px solid @lightgrey;
        }
      }
    }
    .label {
      display: none;
    }
  }
}


#corona-img {
  width: 100%;
}
@media screen and (min-width: 767px) {
  .cms-informations-coronavirus .data-table {
    max-width: 50%;
  }
  #corona-img {
    width: 80%;
    margin: 1em auto;
  }
}




.cms-sos-kinderdoerfer iframe,
.cms-creation iframe,
.cms-morgenpost iframe {
  max-width: 100%;
}
.cms-sos-kinderdoerfer .iframe-container,
.cms-creation .iframe-container,
.cms-morgenpost .iframe-container {
  position: relative;
  padding-bottom: 56.25%; /* ratio 16x9 */
  margin: 0 auto;
  overflow: hidden;
  width: 90%;
  height: auto;
}
.cms-morgenpost .iframe-container {
  width: 100%;
}
.cms-sos-kinderdoerfer .iframe-container iframe,
.cms-creation .iframe-container iframe,
.cms-morgenpost .iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.cms-sos-kinderdoerfer p {
  font-size: 14px;
}
.cms-smart-tv .cms-row {
  margin-bottom: 1em;
}
.cms-page-view.cms-juwelo-experten.op-nav ul.arrow {
  width: 31%;
}

// birthstones
.section-birthstones {
  margin: 40px auto;
  padding: 0 5px;
  .slider-control {
    &.prev {
      left: 5px;
    }
    &.next {
      right: 5px;
    }
  }
  .birthstone-info {
    position: relative;
    text-align: center;
    padding: 0 0 20px;
    .birthstone-info-image {
      max-width: 150px;
      margin: 0 auto;
    }
    .birthstone-info-name {
      margin: 0 0 15px;
      font-family: @serif-normal;
      font-style: italic;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
    }
    .birthstone-info-text {
      margin: 0;
      padding: 0 20px;
      font-size: 13px;
      line-height: 18px;
      p {
        text-align: left;
        &:first-child {
          text-align: center;
        }
      }
    }
    .birthstone-info-link {
      display: inline-block;
      box-sizing: border-box;
      margin: 10px 0 0;
      .sans-serif-bold();
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      color: @pink;
    }
  }

  #birthstones-navigator {
    .form-shadow();
    h2 {
      margin: 0;
      border-top: 1px solid @mediumgrey;
      font-size: 20px;
      line-height: 40px;
      text-align: center;
      text-transform: none;
      i {
        font-style: normal;
      }
    }
    .birthstones-navigator-slider {
      clear: both;
      h2 {
        border-bottom: 1px solid @mediumgrey;
      }

    }
    .current-month {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      .birthstone-info-text {
        height: 54px;
        overflow: hidden;
      }
      .current-month-birthstone-slider {
        ul {
          li {
            flex: 0 0 100%;
          }
        }
      }
    }
    .birthstone-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      box-sizing: border-box;
      width: 100%;
      h2 {
        grid-column: span 3;
        border-bottom: none;
      }
      .birthstone-list-item {
        display: block;
        box-sizing: border-box;
        border-top: 1px solid @mediumgrey;
        border-left: 1px solid @mediumgrey;
        float: left;
        .birthstone-list-item-title {
          box-sizing: border-box;
          margin: 0 0 15px;
          padding: 5px 0;
          background-color: @lightgrey;
          text-align: center;
        }
        .birthstone-list-item-image {
          box-sizing: border-box;
          width: 80px;
          height: 80px;
          margin: 0 auto 20px;
        }
      }
    }
  }
  .birthstones-month {
    margin: 0;
    padding: 40px 0 0;
    border-top: 1px solid @mediumgrey;
    h2 {
      margin: 0 0 10px;
      font-size: 25px;
      line-height: 25px;
      text-align: center;
    }
    .birthstone {
      position: relative;
      margin: 0 0 40px;
    }
    #mondstein,
    #perle,
    #turmalin,
    #topas,
    #tuerkis,
    #zirkon {
      padding-top: 20px;
      border-top: 1px solid @lightgrey;
    }
    .birthstone-products {
      position: relative;
      box-sizing: border-box;
      margin: 20px 0 0;
      padding: 0 20px;
      .birthstone-category-link {
        display: block;
        box-sizing: border-box;
        margin: 20px 0 0;
        .sans-serif-bold();
        font-size: 14px;
        line-height: 14px;
        text-align: center;
      }
    }
    .horizontal-slider {
      ul {
        li {
          width: 50%;
        }
      }
    }
    .show-more-less {
      position: relative;
      z-index: 2;
      display: block;
      margin: -80px 0 0;
      padding: 60px 0 40px;
      background: @white; /* Old browsers */
      background: -moz-linear-gradient(-90deg,  transparent 0%, @white 40%); /* FF3.6-15 */
      background: -webkit-linear-gradient(-90deg,  transparent 0%,@white 40%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(180deg,  transparent 0%,@white 40%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      line-height: 20px;
      color: @pink;
      .show-less,
      .show-more {
        &:before {
          font-family: @icon;
          font-size: 10px;
          padding-right: 3px;
        }
      }
      .show-more {
        display: inline-block;
        &:before {
          content: '\f078';
        }
      }
      .show-less {
        display: none;
        &:before {
          content: '\f077';
        }
      }
      &.show-longtext {
        position: relative;
        margin: 0;
        padding: 10px 0 40px;
        background: none;
        .show-more-less {
          background: none;
        }
        .show-more {
          display: none;
        }
        .show-less {
          display: inline-block;
        }
      }
    }
    .birthstone-longtext {
      max-height: 108px;
      overflow: hidden;
      &.show-longtext {
        max-height: 2000px;
      }
      h3 {
        margin: 0;
        font-size: 13px;
        line-height: 18px;
      }
      h4 {
        margin: .5em .5em 0 0;
        .sans-serif-regular();
        font-size: 13px;
        line-height: 18px;
        float: left;
        &:after {
          padding: 0 0 0 .5em;
          content: '–';
        }
      }
      p {
        margin: .5em 0 0;
        font-size: 13px;
        line-height: 18px;
      }
      .birthstone-info-link {
        display: block;
        margin: 0;
        padding: 20px 0 0;
      }
    }
  }
}
@media screen and (min-width: 480px){
  .section-birthstones {
    #birthstones-navigator {
      .birthstone-list {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        h2 {
          grid-column: span 4;
        }
      }
    }
  }
}
@media screen and (min-width: 640px){
  .section-birthstones {
    #birthstones-navigator {
      .birthstone-list {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        h2 {
          grid-column: span 6;
        }
      }
    }
    .birthstones-month {
      .horizontal-slider {
        ul {
          li {
            width: percentage((1/3));
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px){
  .section-birthstones {
    .birthstone-info {
      .birthstone-info-image {
        margin: 15px auto 0;
      }
      .birthstone-info-name {
        margin: 15px 0;
      }
    }
    #birthstones-navigator {
      h2 {
        margin: 0;
        padding: 10px 20px;
        border-bottom: 1px solid @mediumgrey;
        line-height: 25px;
        a {
          color: @darkgrey;
        }
        i {
          display: block;
        }
      }
      .birthstones-navigator-slider {
        width: 40%;
        float: left;
        clear: none;
        h2 {
          margin: 0;
          border-top: none;
        }
      }
      .birthstone-list {
        display: block;
        width: 60%;
        float: right;
        h2 {
          border-top: none;
          margin: 0;
          border-left: 1px solid @mediumgrey;
        }
        .birthstone-list-item {
          display: block;
          width: percentage((1/4));
          float: left;
        }
      }
      .current-month {
        width: 40%;
        float: left;
      }
    }
    .birthstones-month {
      position: relative;
      h2 {
        text-align: left;
        .current-month-flag {
          padding: 5px 5px 3px;
          background-color: @pink;
          font-size: 12px;
          line-height: 12px;
          color: @white;
          vertical-align: super;
        }
      }
      .birthstone-info {
        position: absolute;
        top: 20px;
        left: 0;
        bottom: 0;
        width: 40%;
        float: left;
        overflow: hidden;
        .birthstone-info-image {
          margin-top: 0;
        }
      }
      .birthstone-products {
        width: 60%;
        float: right;
        margin-top: 0;
      }
      .horizontal-slider {
        ul {
          li {
            width: percentage((1/2));
          }
        }
      }
    }
  }
}
@media screen and (min-width: 769px){
  .section-birthstones {
    padding: 0 10px;
  }
}
@media screen and (min-width: 1024px){
  .section-birthstones {
    h2 {
      font-size: 20px;
    }
    #birthstones-navigator {
      h2 {
        i {
          display: inline;
        }
      }
      .birthstone-list {
        .birthstone-list-item {
          width: percentage((1/4));
        }
      }
    }
    .birthstones-month {
      .horizontal-slider {
        ul {
          li {
            width: percentage((1/3));
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1200px){
  .section-birthstones {
    #birthstones-navigator {
      .birthstone-info {
        .birthstone-info-image {
          margin: 0 auto;
        }
        .birthstone-info-name {
          margin: 0 0 10px;
        }
        .birthstone-info-link {
          margin: 10px 0 0;
        }
      }
      .birthstone-list {
        .birthstone-list-item {
          width: percentage((1/6));
        }
      }
    }
  }
}


// brands slider
.brands-teaser {
  position: relative;
  text-align: center;
  margin: 0 0 30px;
  padding: 20px 0 0;
  h2 {
    margin: 0 0 20px;
    .sans-serif-regular();
    font-size: 24px;
    text-align: center;
    text-transform: none;
    font-weight: normal;
    strong {
      .sans-serif-regular();
      font-weight: normal;
    }
  }
  a {
    position: relative;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 10px;
    padding: 0 0 140%;
    float: left;
    .x-image-container {
      top: 0;
      left: 5px;
      right: 5px;
    }
  }
}
@media screen and (min-width: 360px) {
  .brands-teaser {
    a {
      width: percentage((1/2));
      padding: 0 0 70%;
    }
  }
}
@media screen and (min-width: 640px) {
  .brands-teaser {
    margin: 0 40px 30px;
    h2 {
      font-size: 30px;
    }
  }
}
@media screen and (min-width: 768px) {
  .brands-teaser {
    margin: 0 40px 70px;
  }
}
@media screen and (min-width: 769px) {
  .brands-teaser {
    a {
      width: percentage((1/3));
      padding: 0 0 percentage((1.4/3));
      .x-image-container {
        left: 10px;
        right: 10px;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .brands-teaser {
    padding: 0;
    h2 {
      margin: 0 0 40px;
    }
  }
}
@media screen and (min-width: 1025px) {
  .brands-teaser {
    a {
      width: percentage((1/4));
      padding: 0 0 35%;
    }
  }
}


// page ringsizes
.cms-ringgroessen {
  .page-content {
    .col-2-set {
      padding: 0;
      .video {
        position: relative;
        width: 100%;
        margin: 0 0 20px;
        padding-bottom: 56.25%;
        padding-top: 0;
        height: 0;
        overflow: hidden;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

// flex Layout for order
.page-ringsizes {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  .ringsize-slider {
    order: 1;
  }
  .ringsize-video {
    order: 2;
    display: flex;
    flex-direction: column;
  }
  .ringsize-measuring-methods {
    order: 3;
  }
  .ringsize-table {
    order: 4;
  }
  .featured-products {
    order: 5;
  }
}


.page-ringsizes-determiner {
  box-sizing: border-box;
  margin: 0 5px 20px;
  padding: 10px;
  clear: both;
  // .form-shadow();
  text-align: center;
  h2 {
    margin: 0 0 8px;
    .sans-serif-regular();
    font-size: 13px;
    line-height: 18px;
    text-align: left;
    text-transform: none;
  }
  p {
    font-size: 11px;
    line-height: 14px;
    text-align: left;
  }
  .btn {
    font-size: 13px;
    line-height: 26px;
  }
}
.ringsizes-determine-pdf-text {
  box-sizing: border-box;
}
.ringsizes-determine-pdf-preview {
  position: relative;
  display: block;
  box-sizing: border-box;
  margin: 20px 0 0;
  padding: 10px 20px;
  background-color: @lightgrey;
  &-title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    box-sizing: border-box;
    height: 34px;
    padding: 10px 20px;
    background-color: rgba(0,0,0,0.5);
    text-align: center;
    color: @white;
  }
}
@media screen and (min-width: 480px){
  .page-ringsizes-determiner {
    h2 {
      font-size: 16px;
      line-height: 20px;
    }
    p {
      font-size: 13px;
      line-height: 16px;
      text-align: left;
    }
  }
}
@media screen and (min-width: 640px){
  .ringsizes-determine-pdf-text {
    width: 50%;
    padding-left: 10px;
    float: right;
  }
  .ringsizes-determine-pdf-preview {
    width: 50%;
    margin: 0;
    padding: 10px;
    float: left;
  }
}
@media screen and (min-width: 769px){
  .page-ringsizes-determiner {
    margin: 0 10px 20px;
    padding: 10px 20px;
  }
}


/* famous ringsizes buttons */
.page-ringsizes-buttons {
  width: 100%;
  clear: both;
  box-sizing: border-box;
  margin: 20px 0;
  h2 {
    padding: 0 5px;
    font-size: 16px;
    line-height: 20px;
  }
}
.ringsize-button-legend {
  position: relative;
  display: block;
  width: percentage((2/3));
  height: 110px;
  box-sizing: border-box;
  margin: 0 0 10px;
  padding: 0 5px;
  float: left;
  .text {
    .flex-item-shrink();
  }
  .arrow {
    position: relative;
    .flex-item-adjust();
    height: 1px;
    overflow: visible;
    background-color: @mediumgrey;
    &:after {
      position: absolute;
      right: -4px;
      content: '>';
    }
  }
  .size-local {
    .flex-rows();
    height: 38px;
    margin: 10px 5px 15px;
    font-size: 11px;
    line-height: 40px;
    .arrow {
      margin: 19px 17px 20px 10px;
      &:after {
        top: -18px;
      }
    }
  }
  .size-diameter,
  .size-circumference {
    .flex-rows();
    margin: 0 5px;
    font-size: 11px;
    line-height: 20px;
    .arrow {
      margin: 9px 17px 10px 10px;
      &:after {
        top: -9px;
      }
    }
  }
}
.ringsize-button {
  position: relative;
  display: block;
  width: percentage((1/3));
  height: 110px;
  box-sizing: border-box;
  margin: 0 0 10px;
  float: left;
  text-align: center;
  &.collapsed {
    display: none;
  }
  &:hover {
    text-decoration: none;
    &:before {
      border: 1px solid @pink;
      box-shadow: 0px 0px 2px 0px @pink;
    }
  }
  &:before {
    position: absolute;
    top: 0;
    left: 5px;
    right: 5px;
    bottom: 0;
    z-index: -1;
    display: block;
    border: 1px solid @mediumgrey;
    border-radius: 5px;
    content: '';
    background: #eee; /* Old browsers */
    background: -moz-linear-gradient(190deg,  #fff 0%, #f2f2f2 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(180deg,  #fff 0%, #f2f2f2 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(180deg,  #fff 0%, #f2f2f2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  .size-local {
    display: block;
    width: 36px;
    height: 36px;
    margin: 10px auto 15px;
    border: 1px solid @pink;
    .rounded();
    font-size: 15px;
    line-height: 40px;
  }
  .size-diameter,
  .size-circumference {
    display: block;
    font-size: 11px;
    line-height: 20px;
    color: @darkgrey;
  }
}
.ringsizes-buttons-show-all {
  display: block;
  width: 100%;
  margin: 10px 0 0;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  color: @pink;
  &:after {
    display: block;
    width: 100%;
    font-family: @icon;
    font-size: 18px;
    line-height: 10px;
    content: '\f107';
  }
}
.show-all {
  .collapsed {
    display: block;
  }
  .ringsizes-buttons-show-all {
    &:after {
      content: '\f106';
    }
  }
}
@media screen and (min-width: 540px){
  .page-ringsizes-buttons {
    &:before,
    &:after {
      border-top: none;
    }
  }
  .ringsize-button-legend {
    width: percentage((1/3));
    .size-diameter,
    .size-circumference {
      .flex-rows();
      font-size: 12px;
    }
  }
  .ringsize-button {
    width: percentage((1/6));
    &.collapsed {
      display: block;
    }
  }
  .ringsizes-buttons-show-all {
    display: none;
  }
}
@media screen and (min-width: 769px){
  .page-ringsizes-buttons {
    padding: 0 5px;
  }
}
@media screen and (min-width: 1024px){
  .ringsize-button-legend {
    width: percentage((2/12));
  }
  .ringsize-button {
    width: percentage((1/12));
  }
}


/* ringsize table */

.page-ringsizes-table {
  width: 100%;
  box-sizing: border-box;
  padding: 0 5px;
  clear: both;
  h2 {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    clear: both;
  }
}
.ringsize-table {
  position: relative;
  display: table;
  box-sizing: border-box;
  width: 100%;
  margin: 20px 0 60px;
  font-size: 10px;
  line-height: 18px;
  a {
    color: @black;
  }
  .ringsize-table-row {
    display: table-row;
    &.grouped-gold {
      background-color: lighten(@lightgold, 2.5%);
    }
    &.grouped-white {
      background-color: @white;
    }
  }
  .ringsize-table-col {
    display: table-cell;
    padding: 8px 3px 4px;
    border-top: 1px solid @mediumgrey;
    text-align: center;
    vertical-align: middle;
    &.ringsize-table-col-title {
      padding: 7px 3px;
      .sans-serif-bold();
      font-size: 0;
      line-height: 0;
      background-color: @lightgold;
      border-top: none;
      &:after {
        display: block;
        width: 20px;
        height: 20px;
        margin: 0 auto;
        .rounded();
        border: 1px solid @mediumgrey;
        background-image: url('../images/ringsize-flags.png');
        background-size: 700%;
        content: '';
      }
      &.diameter-size,
      &.circumference-size {
        font-size: 10px;
        line-height: 18px;
        &:after {
          display: none;
        }
      }
      &.ringsize-de:after,
      &.de-size:after {
        background-position: 0 0;
      }
      &.uk-scale:after,
      &.en-size:after {
        background-position: -100% 0;
      }
      &.fr-scale:after,
      &.fr-size:after {
        background-position: -200% 0;
      }
      &.us-scale:after,
      &.us-size:after {
        background-position: -300% 0;
      }
      &.ringsize-it:after,
      &.it-size:after {
        background-position: -400% 0;
      }
      &.es-size:after {
        background-position: -500% 0;
      }
      &.nl-size:after {
        background-position: -600% 0;
      }
    }
  }
  .ringsize-local {
    display: none;
  }
}

// columns by language
.page-ringsizes-table-de {
  .ringsize-local-de { display: table-cell; }
  .ringsize-de { display: none; }
}
.page-ringsizes-table-en {
  .ringsize-local-en { display: table-cell; }
  .uk-scale { display: none; }
}
.page-ringsizes-table-es {
  .ringsize-local-es { display: table-cell; }
  .ringsize-it { display: none; }
}
.page-ringsizes-table-fr {
  .ringsize-local-fr { display: table-cell; }
  .ringsize-it { display: none; }
}
.page-ringsizes-table-it {
  .ringsize-local-it { display: table-cell; }
  .ringsize-it { display: none; }
}
.page-ringsizes-table-nl {
  .ringsize-local-nl { display: table-cell; }
  .ringsize-it { display: none; }
}
@media screen and (min-width: 360px){
  .ringsize-table {
    font-size: 11px;
    .ringsize-table-col {
      &.ringsize-table-col-title {
      }
    }
  }
}
@media screen and (min-width: 480px){
  .ringsize-table {
    .ringsize-table-col {
      &.ringsize-table-col-title {
      }
    }
  }
}
@media screen and (min-width: 540px){
  .page-ringsizes-table {
    &:before {
      border-top: none;
      margin: 0;
    }
  }
}
@media screen and (min-width: 640px){
  .ringsize-table {
    font-size: 12px;
    line-height: 20px;
    .ringsize-table-col {
      padding: 2px 10px;
      &.ringsize-table-col-title {
        padding: 7px 10px;
      }
    }
  }
}
@media screen and (min-width: 1024px){
  .ringsize-table {
    .ringsize-table-col {
      &.ringsize-table-col-title {
        font-size: 12px;
        line-height: 20px;
        &:after {
          display: inline-block;
          margin: 0 0 -7px 5px;
        }
      }
    }
  }
}

#ringsize-slider {
  position: relative;
  height: 0;
  margin: 0 5px 40px;
  padding: 10px 13px;
}
#ringsize-slider-track {
  position: relative;
  width: 100%;
  height: 20px;
  background-image: url('../images/ringsize-slider-bg.png');
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}
#ringsize-slider-handle {
  position: absolute;
  left: 0;
  &-bar {
    position: relative;
    display: block;
    width: 26px;
    height: 26px;
    margin: -15px 0 0 0;
    cursor: col-resize;
    &:before, &:after {
      display: block;
      position: absolute;
      .rounded;
      background-color: @gold;
      content: '';
    }
    &:before {
      width: 12px;
      height: 12px;
      top: -1px;
      left: 7px;
    }
    &:after {
      width: 26px;
      height: 26px;
      top: -8px;
      left: 0;
      opacity: .5;
      .shadow();
    }
  }
}
#ring_size_slider_table {
  width: auto;
  margin: 40px 5px 10px;
  border: 1px solid @mediumgrey;
  .ringsize-table-col {
    border-left: 1px solid @mediumgrey;
    &:first-child {
      border-left: none;
    }
    &:not(.ringsize-table-col-title){
      font-size: 10px;
      line-height: 30px;
      text-align: center;
    }
    &.uk-scale,
    &.us-scale {
      min-width: 35px;
    }
    .ringsize-table-col-title {
      background-color: @lightgrey;
    }
    input {
      max-width: 50px;
      margin: 0 auto;
      text-align: center;
    }
  }
}
@media screen and (min-width: 400px) {
  #ring_size_slider_table {
    .ringsize-table-col {
      min-width: 35px;
      &:not(.ringsize-table-col-title){
        font-size: 12px;
        line-height: 30px;
      }
    }
  }
}
@media screen and (min-width: 769px) {
  #ringsize-slider {
    margin: 0 10px 40px;
  }
  #ring_size_slider_table {
    margin: 40px 10px 10px;
    .ringsize-table-col {
      &:not(.ringsize-table-col-title){
        font-size: 20px;
        line-height: 40px;
      }
    }
  }
}

@media screen and (min-width: 1025px) {
  #page-ringsizes {
    .page-ringsizes-buttons {
      order: 2;
    }
    .page-ringsizes-determiner {
      order: 1;
    }
  }
}

/* lexicon article gemstone */

.lexicon-article,
.legal-article {
  margin: 0 5px;
  section {
    h1 {
      padding: 0 !important;
    }
    h2 {
      margin: 0 0 11px;
      font-size: 18px;
      line-height: 22px;
    }
    h3 {
      margin: 33px 0 11px;
      font-size: 15px;
      line-height: 22px;
    }
    h2 + h3,
    h2 + .small-image + h3 {
      margin: 22px 0 11px;
    }
    h4 {
      margin: 22px 0 0;
      font-size: 13px;
      line-height: 22px;
    }
    p {
      margin: 0 0 11px;
      font-size: 13px;
      line-height: 22px;
    }
    blockquote {
      p {
        display: block;
        font-family: @serif-normal;
        font-style: italic;
        font-size: 15px;
        line-height: 25px;
        text-align: center;
        color: @pink;
        &:before {
          content: '„';
        }
        &:after {
          content: '“';
        }
      }
      cite {
        display: block;
        font-style: normal;
        font-size: 13px;
        line-height: 25px;
        text-align: left;
        color: @darkgrey;
      }
    }
    p.to-top {
      clear: both;
    }
    .anchor {
      white-space: nowrap;
      display: block;
      margin-top: 10px;
    }
    ol.numbered {
      display: inline-block;
      padding-left: 15px;
      list-style: decimal;
      list-style-position: outside;
      li {
        &::marker {
          .sans-serif-bold();
        }
      }
    }
    ul.bulleted {
      display: inline-block;
      padding-left: 15px;
      list-style: disc;
      list-style-position: outside;
    }
  }
  .lexicon-article-title {
    box-sizing: border-box;
    margin: 10px 0 20px;
    padding: 0 5px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
  section:not(.section-header),
  .lexicon-article-section {
    position: relative;
    display: block;
    box-sizing: border-box;
    margin: 0 0 40px;
    padding: 0 5px;
    clear: both;
    text-align: left;
    .lexicon-article-section-text {
      position: relative;
      &-image {
        max-width: 400px;
        height: auto;
        margin: 20px auto;
      }
    }
    .lexicon-article-section-media {
      .video {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        padding-top: 0;
        height: 0;
        overflow: hidden;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .video-meta {
        width: 100%;
        h3 {
          margin: 20px 0;
        }
        tr {
          th {
            border-right: 1px solid @mediumgrey;
            .sans-serif-regular();
            font-weight: normal;
          }
          td, th {
            padding: 7px 5px 5px;
            border-bottom: 1px solid @mediumgrey;
          }
          &:first-child {
            td, th {
              border-top: 1px solid @mediumgrey;
              background-color: @lightgrey;
            }
          }
        }
      }
    }
    .gem-care-table {
      margin: 0;
      h3 {
        margin: 0;
      }
      .table {
        border-top: none;
        margin: 0 0 20px;
      }
    }
  }
  section.section-shop {
    margin: 0 20px 40px;
    padding: 0;
    h2 {
      margin: 30px -20px;
    }
    .to-top-link {
      margin: 0 -20px;
    }
  }
  .gem-pages-nav {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    ul {
      padding: 0 20px;
      border-left: 1px solid @mediumgrey;
      &:nth-child(even){
        border-right: 1px solid @mediumgrey;
      }
      li {
        margin: 0;
        .sans-serif-regular();
        line-height: 30px;
      }
    }
  }
  .to-shop-button {
    margin: 40px 0 20px;
    text-align: center;
  }
  .small-image {
    max-width: 300px;
    margin: 20px auto;
    .caption {
      display: block;
      margin: 5px 0 0;
      text-align: center;
      line-height: 20px;
      font-style: italic;
    }
  }
  .wide-image {
    max-width: 100%;
    margin: 20px auto;
    .caption {
      display: block;
      margin: 5px 0 0;
      text-align: center;
      line-height: 20px;
      font-style: italic;
    }
  }
}
.legal-article {
  .customer-service-telephone {
    position: relative;
    display: inline-block;
    padding-left: 36px;
    .sans-serif-bold();
    font-size: 20px;
    line-height: 20px;
    .country-code {
      position: absolute;
      top: -3px;
      left: 0;
      display: inline-block;
      width: 26px;
      height: 20px;
      background-image: url(../images/flags.png);
      background-position-y: 0;
      &.at { background-position-x: -26px; }
      &.be { background-position-x: -156px; }
      &.ch { background-position-x: -364px; }
      &.de { background-position-x: 0; }
      &.en { background-position-x: -390px; }
      &.es { background-position-x: -286px; }
      &.eu { background-position-x: -52px; }
      &.fr { background-position-x: -78px; }
      &.it { background-position-x: -104px; }
      &.nl { background-position-x: -182px; }
    }
  }
}
#lexicon-article-section-varieties .lexicon-article-section-text .image {
  position: relative;
  &:before {
    display: block;
    width: 100%;
    clear: both;
    content: '';
  }
}

#lexicon-article-section-thumbnail {
  position: relative;
  box-sizing: border-box;
  margin: 0 5px 20px;
  padding: 0 0 100%;
  overflow: hidden;
  .lexicon-article-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
#lexicon-article-section-index {
  ol {
    margin: 0;
    li {
      margin: 0 0 10px;
      a {
        color: @darkgrey;
        border-bottom: 1px solid @mediumgrey;
      }
    }
  }
}

@media screen and (max-width: 638px){
  #lexicon-article-section-thumbnail {
    padding: 0 0 50%;
    .lexicon-article-thumbnail {
      top: -50%;
    }
  }
}
@media screen and (min-width: 639px){
  #lexicon-article-section-thumbnail {
    padding: 0 0 percentage((1/3));
    .lexicon-article-thumbnail {
      top: 0;
    }
  }
}
.section-varieties,
#lexicon-article-section-varieties {
  .variety {
    clear: both;
    margin: 40px 0;
  }
  .variety-image {
    width: 120px;
    height: 120px;
    margin: 0 10px 10px 0;
    float: left;
  }
}
#lexicon-article-section-products {
  text-align: center;
  .btn {
    margin: 30px 0 0;
  }
}
#lexicon-article-section-lexicon {
  .lexicon-article-section-text {
    border: 1px solid @mediumgrey;
  }
  .list-group-nav {
    width: 100%;
    float: left;
    border-bottom: 1px solid @mediumgrey;
    li {
      box-sizing: border-box;
      width: 25%;
      margin: 0;
      float: left;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      border-left: 1px solid @mediumgrey;
      &:first-child,
      &:nth-child(5){
        border-left: none;
      }
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8){
        border-top: 1px solid @mediumgrey;
      }
      &.list-group-show {
        color: @white;
        background-color: @pink;
      }
    }
  }
  .list-group {
    display: none;
    box-sizing: border-box;
    width: 100%;
    float: left;
    padding: 10px;
    &.list-group-show {
      display: block;
    }
    .link-list {
      width: 50%;
      float: left;
      li {
        width: 100%;
        .sans-serif-regular();
        a {
          .sans-serif-light();
        }
      }
    }
  }
}
@media screen and (min-width: 640px){
  .lexicon-article,
  .legal-article {
    #lexicon-article-section-thumbnail {
      padding: 0 0 percentage((1/3));
    }
    #lexicon-article-section-lexicon {
      .list-group-nav {
        li {
          width: 12.5%;
          &:nth-child(5){
            border-left: 1px solid @mediumgrey;
          }
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8){
            border-top: none;
          }
        }
      }
      .list-group {
        .link-list {
          width: percentage((1/3));
        }
      }
    }
    .small-image {
      margin: 0;
      &.left {
        float: left;
        margin: 0 20px 20px 0;
      }
      &.right {
        float: right;
        margin: 0 20px 0 20px;
      }
    }
  }
}
@media screen and (min-width: 768px){
  .lexicon-article,
  .legal-article {
    section {
      blockquote {
        p {
          font-size: 20px;
          line-height: 30px;
        }
        cite {
          font-size: 15px;
          line-height: 25px;
        }
      }
    }
    .lexicon-article-section {
      .lexicon-article-section-text {
        &-image {
          width: 100%;
          max-width: 600px;
        }
      }
      .lexicon-article-section-media {
        .video {
          width: 50%;
          float: left;
          padding-bottom: 28.125%;
        }
        .video-meta {
          box-sizing: border-box;
          width: 50%;
          float: right;
          padding-left: 20px;
          h3 {
            margin: 0 0 20px;
          }
        }
      }
    }
    #lexicon-article-section-index {
      ol {
        width: percentage((1/3));
        float: left;
      }
    }
    .gem-pages-nav {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      ul {
        &:nth-child(even){
          border-right: none;
        }
        &:nth-child(4){
          border-right: 1px solid @mediumgrey;
        }
      }
    }
    .small-image {
      margin: 0;
      max-width: 400px;
      &.left {
        float: left;
        margin: 0 40px 40px 0;
      }
      &.right {
        float: right;
        margin: 0 40px 0 40px;
      }
    }
  }
}
@media screen and (min-width: 769px){
  .lexicon-article,
  .legal-article {
    margin: 0 10px;
    .lexicon-article-thumbnail {
      margin: 0 0 20px;
      padding: 0 10px;
    }
    .lexicon-article-section {
      padding: 0 10px;
    }
    .gem-pages-nav {
      grid-column-gap: 20px;
    }
  }
}
@media screen and (min-width: 1024px){
  .lexicon-article {
    section {
      h2 {
        margin: 0 0 20px;
        font-size: 20px;
        line-height: 25px;
      }
      h3 {
        margin: 40px 0 20px;
        font-size: 18px;
        line-height: 24px;
      }
      h4 {
        margin: 30px 0 0;
        font-size: 15px;
        line-height: 25px;
      }
      p {
        margin: 0 0 15px;
        font-size: 15px;
        line-height: 25px;
      }
    }
    section:not(.section-header),
    .lexicon-article-section {
      margin: 0 0 50px;
      .lexicon-article-section-text {
        &-image {
          max-width: 800px;
        }
      }
    }
    section.section-shop {
      margin: 0 20px 50px;
    }
  }
}
.no-touchevents {
  .zoom-hint-touch {
    display: none;
  }
  #gemstones-origin-map {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    .hover-image {
      position: relative;
      z-index: 2;
      margin: 20px auto;
      max-width: 800px;
    }
    &:hover img {
      opacity: 0;
      transition: opacity 0.7s;
      cursor: zoom-in;
    }

    #zoom_box {
      position: absolute;
      overflow: hidden;
      box-sizing: border-box;
      width: 100%;
      padding-bottom: 50%;
      img {
        position: absolute;
        opacity: 1;
        max-width: none;
      }
      picture {
        display: block;
        position: absolute;
        opacity: 1;
        max-width: none;
        img {
          position: relative;
        }
      }
      .close {
        display: none;
      }
    }
  }
}
.touchevents {
  .zoom-hint-touch:before {
    display: inline-block;
    width: 11px;
    height: 11px;
    margin: 0 5px 0 0;
    border: 1px solid @darkgrey;
    .rounded();
    .sans-serif-bold();
    line-height: 12px;
    text-align: center;
    content: '+';
  }
  #gemstones-origin-map {
    position: relative;
    .hover-image {
      position: relative;
      z-index: 2;
    }
    #zoom_box {
      display: none;
      position: fixed;
      top: 10px !important;
      left: 10px !important;
      right: 10px !important;
      bottom: 10px !important;
      width: auto !important;
      height: auto !important;
      overflow: auto;
      z-index: 300;
      .popup-shadow();
      img {
        position: absolute;
        max-width: none;
        z-index: 1;
      }
      .close {
        position: fixed;
        top: 10px;
        right: 10px;
        display: block;
        width: 40px;
        height: 40px;
        text-align: center;
        z-index: 2;
        &:after {
          display: block;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-family: @icon;
          font-size: 20px;
          content: '\e901';
          color: @white;
        }
      }
    }
    &.zoom-active {
      .hover-image {
        display: none;
      }
      #zoom_box {
        display: block;
      }
    }
  }
}

.varieties-book {
  position: relative;
  .form-shadow();
  margin: 20px 0;
  .varieties-book-index {
    padding: 0 5px;
    &:after {
      display: block;
      clear: both;
      content: '';
      width: 100%;
      padding: 15px 0 0;
    }
    &-item {
      box-sizing: border-box;
      width: 16.875%;
      margin: 3.125% 1.5625% 0;
      float: left;
      border: 1px solid @mediumgrey;
      cursor: pointer;
      &-image {
        max-width: 40px;
        margin: 0 auto;
      }
      &-name {
        display: none;
      }
    }
  }
  .varieties-book-pages {
    position: relative;
    border-top: 1px solid @mediumgrey;
    .varieties-book-page {
      display: none;
      flex-direction: column;
      &.current-page {
        display: flex;
      }
      .varieties-book-page-title {
        order: 1;
        margin: 10px 0 0;
        .sans-serif-light();
        text-transform: uppercase;
        text-align: center;
      }
      .varieties-book-page-text {
        order: 3;
        padding: 0 10px;
      }
      .varieties-book-page-image {
        order: 2;
        max-width: 300px;
        margin: 0 auto;
      }
      .varieties-book-page-products {
        order: 4;
        text-align: center;
        .products-grid {
          grid-template-columns: 1fr 1fr 1fr;
          li {
            .products-grid-item-wrapper {
              .add-to-links {
                display: none;
              }
              .image-box {
                border: none;
                padding-bottom: 100%;
                .product-image {
                  top: 0;
                }
                .brand,
                .ringsize,
                .flags {
                  display: none;
                }
              }
              .details-box {
                .price-box {
                  text-align: center;
                }
                .product-name,
                .materials {
                  display: none;
                }
              }
            }
          }
        }
        .btn{
          margin: 20px auto 15px;
        }
      }
    }
  }
}
@media screen and (min-width: 480px) {
  .varieties-book {
    .varieties-book-index {
      &-item {
        &-image {
          max-width: 60px;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .varieties-book {
    padding: 0;
    display: flex;
    flex-direction: row;
    .varieties-book-index {
      flex: 1 0 48.2%;
      margin: 0 0.6% 0 1.2%;
      padding: 0;
      .form-shadow();
      ul {
        box-sizing: border-box;
        width: 50%;
        margin: 20px 0;
        padding: 0;
        float: left;
        &:first-child {
          border-right: 1px solid @mediumgrey;
        }
      }
      &-item {
        width: 100%;
        margin: 0;
        padding: 0 5px;
        border: none;
        &-name {
          display: block;
          padding: 10px 0 0;
          font-size: 11px;
          line-height: 45px;
          text-transform: uppercase;
        }
        &-image {
          float: left;
          margin: 0 5px 0 0;
        }
      }
    }
    .varieties-book-pages {
      flex: 1 0 48.2%;
      margin: 0 1.2% 0 0.8%;
      .form-shadow();
      .varieties-book-page {
        display: none;
        &.current-page {
          display: -webkit-flex;
          display: flex;
          -ms-flex-direction: column;
          flex-direction: column;
          flex: 1;
          -webkit-flex: 1;
          -ms-flex: 1;
        }
        .varieties-book-page-title {
          margin: 20px 0;
        }
        .varieties-book-page-text {
          order: 2;
          flex: 1 0 auto;
          padding: 0 10px;
        }
        .varieties-book-page-image {
          order: 3;
          width: 300px;
          height: 300px;
          margin: 0 auto;
        }
        .varieties-book-page-products {
          order: 4;
          text-align: center;
          .products-grid {
            float: 1 0 50%;
          }
          .btn{
            margin: 20px auto 15px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .varieties-book {
    .varieties-book-index {
      width: 49.5%;
    }
    .varieties-book-pages {
      width: 49.5%;
    }
  }
}

/* alternative header */
#lexicon-article-section-header,
.section-header {
  display: grid;
  grid-template-columns: 1fr;
  picture {
    display: block;
    img {
      width: 100%;
    }
  }
  .lexicon-article-index {
    h2 {
      margin: 15px 0 5px;
      font-size: 15px;
      line-height: 20px;
    }
    li {
      margin: 0;
      line-height: 24px;
    }
  }
}
@media screen and (min-width: 640px) {
  #lexicon-article-section-header,
  .section-header {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content auto auto;
    grid-column-gap: 20px;
    margin-top: 10px;
    h1.lexicon-article-title {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 2;
      margin: 0;
      padding: 0 0 10px;
      border-bottom: 1px solid @mediumgrey;
      text-align: left;
    }
    .lexicon-article-blockquote,
    .lexicon-article-thumbnail {
      grid-row-start: 1;
      grid-row-end: 4;
      grid-column-start: 2;
      grid-column-end: 3;
      margin: 0;
      padding: 0;
    }
    .lexicon-article-blockquote {
      align-self: center;
      blockquote {
        padding: 0 10%;
      }
    }
    .lexicon-article-index {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }
}
@media screen and (min-width: 769px) {
  #lexicon-article-section-header,
  .section-header {
    grid-column-gap: 40px;
  }
}
@media screen and (min-width: 1024px) {
  #lexicon-article-section-header,
  .section-header {
    grid-template-columns: 1fr 1fr 1fr;
    .lexicon-article-blockquote,
    .lexicon-article-thumbnail {
      grid-column-start: 2;
      grid-column-end: 4;
    }
  }
}

/* content accordeon */
.content-accordeon {
  position: relative;
  border: 1px solid @mediumgrey;
  & > .item {
    position: relative;
    top: 0;
    h3.title {
      position: relative;
      margin: 0;
      padding: 6px 20px 4px 5px;
      border-top: 1px solid @mediumgrey;
      font-size: 12px;
      line-height: 30px;
      cursor: pointer;
      &:after {
        position: absolute;
        top: 5px;
        right: 5px;
        font-family: @icon;
        font-size: 16px;
        content: '\f107';
      }
    }
    &:first-child {
      .title {
        border-top: none;
      }
    }
    .content {
      max-height: 0;
      -webkit-transition: max-height 0.15s;
      -moz-transition: max-height 0.15s;
      transition: max-height 0.15s;
      overflow: hidden;
      padding: 0 5px;
    }
    &.open {
      .title {
        &:after {
          content: '\f106';
        }
      }
      .content {
        max-height: 4000px;
        padding: 5px;
      }
    }
    .customer-service-contact {
      .sans-serif-bold();
      font-size: 15px;
      line-height: 20px;
      .flag {
        float: left;
      }
      .email-icon {
        float: left;
        width: 26px;
        margin-right: 5px;
        &:before {
          font-size: 20px;
          line-height: 15px;
          color: @pink;
        }
      }
    }
  }
}
@media screen and (min-width: 769px){
  .content-accordeon {
    margin: 20px auto;
    & > .item {
      h3.title {
        margin: 0;
        padding: 16px 40px 14px 20px;
        font-size: 15px;
        line-height: 26px;
        &:after {
          font-size: 20px;
          top: 15px;
          right: 15px;
        }
      }
      .content {
        padding: 0 20px;
      }
      &.open {
        .content {
          padding: 15px 10px;
        }
      }
    }
  }
}

.gem-colors,
.famous-gemstones,
.all-gemstones {
  box-sizing: border-box;
  width: 100%;
  padding: 0 5px;
  clear: both;
  li {
    box-sizing: border-box;
    width: 50%;
    float: left;
    margin: 0 0 10px;
    &.marker {
      width: 100%;
      line-height: 30px;
      background-color: @lightgrey;
    }
    a {
      line-height: 30px;
    }
  }
}
.gem-colors,
.famous-gemstones {
  li {
    &:first-child {
      clear: left;
    }
    a {
      .gem-icons();
      &:before {
        margin: 0 5px 0 0;
      }
    }
  }
}
.all-gemstones {
  li {
    padding: 0 10px;
  }
}
@media screen and (min-width: 640px){
  .gem-colors,
  .famous-gemstones,
  .all-gemstones {
    li {
      width: percentage((1/3));
      &.marker {
        width: 100%;
      }
    }
  }
}
@media screen and (min-width: 768px){
  .gem-colors,
  .famous-gemstones,
  .all-gemstones {
    li {
      width: percentage((1/4));
      &.marker {
        width: 100%;
      }
    }
  }
}
@media screen and (min-width: 769px){
  .gem-colors,
  .famous-gemstones,
  .all-gemstones {
    padding: 0 10px;
  }
}
@media screen and (min-width: 1024px){
  .gem-colors,
  .famous-gemstones,
  .all-gemstones {
    li {
      width: percentage((1/5));
      &.marker {
        width: 100%;
      }
    }
  }
}

// about page
.about-page {
  margin: 0 0 40px;
  h2 {
    margin: 40px 0 10px;
    text-transform: none;
  }
  .advantages {
    ul {
      &:before {
        display: none;
      }
      li {
        position: relative;
        margin: 0;
        padding: 0 0 0 15px;
        list-style: none;
        .sans-serif-regular();
        font-size: 11px;
        line-height: 18px;
        p {
          margin: 0;
          line-height: 18px;
        }
        &:before {
          position: absolute;
          left: 0;
          top: 0;
          display: inline-block;
          font-family: @icon;
          content: '\f00c';
          color: @green;
        }
      }
    }
  }
  #trustedshops-custom-widget {
    margin: 10px 0 20px;
  }
}
@media screen and (min-width: 360px){
  .about-page {
    .advantages {
      ul {
        li {
          padding: 0 0 0 18px;
          font-size: 13px;
          line-height: 21px;
          p {
            line-height: 21px;
          }
        }
      }
    }
    .content-accordeon {
      max-width: none;
    }
  }
}
@media screen and (min-width: 768px){
  .about-page {
    #trust-custom-badge-horizontal {
      width: calc(100% - 20px);
      margin: 0 10px;
    }
    #trustedshops-custom-widget {
      margin: 20px 10px 40px;
    }
    #international-shipping-costs-table {
      width: 50%;
      float: left;
      margin: 20px 40px 40px 10px;
    }
  }
}

.jewelry-categories {
  h1 {
    margin: 0 5px 20px;
  }
  h2 {
    margin: 0 0 15px;
    padding: 3px 5px 0;
    font-size: 13px;
    line-height: 27px;
    color: @white;
    background-color: @juwelogold;
  }
  ul {
    width: 100%;
    margin: 0 0 30px;
    float: left;
  }
  li {
    box-sizing: border-box;
    margin: 0;
    line-height: 30px;
    a {
      color: @darkgrey;
    }
  }
}
@media screen and (min-width: 400px) {
  .jewelry-categories {
    .col {
      width: 50%;
    }
  }
}
@media screen and (min-width: 768px) {
  .jewelry-categories {
    .col {
      width: 25%;
    }
  }
}
@media screen and (min-width: 769px) {
  .jewelry-categories {
    h1 {
      margin: 0 10px 20px;
    }
  }
}

.about-page {
  .advantages {
    position: relative;
    ul {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
  }
  .content-accordeon {
    & > .item {
      .content-accordeon-item-content-section-index {
        box-sizing: border-box;
        padding: 0 10px;
      }
      .content {
        padding: 0;
      }
      &.open {
        .content {
          max-height: 10000px;
        }
      }
    }
  }
  .grid-row {
    margin: 0 0 10px;
    .content-accordeon-item-content-section-image {
      position: relative;
      width: 100%;
      padding-bottom: 66.666666%;
      .x-adaptive-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      &.portrait {
        padding-bottom: 136.6%;
      }
    }
    .content-accordeon-item-content-section-content {
      text-align: center;
      padding: 15px 40px;
      align-self: center;
      h4 {
        .sans-serif-regular();
        font-size: 15px;
        text-transform: none;
      }
    }
    &.images-row {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 10px;
      .row-image {
        position: relative;
        width: 100%;
        padding-bottom: 75%;
        .x-adaptive-image {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
}
article.about-page {
  margin: 0 5px;
  h1 {
    padding: 0 !important;
  }
}
@media screen and (min-width: 640px) {
  .about-page {
    .advantages {
      ul {
        bottom: auto;
        top: 5px;
      }
    }
  }
}
@media screen and (min-width: 740px) {
  .about-page {
    .advantages {
      ul {
        top: 25px;
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .about-page {
    .advantages {
      ul {
        top: 25px;
        left: 20px;
      }
    }
    .content-accordeon {
      & > .item {
        .content-accordeon-item-content-section-index {
          padding: 0 20px;
          width: 50%;
          float: left;
        }
      }
    }
    .grid-row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 10px;
      margin: 0 0 10px;
      &:last-child {
        margin: 0;
      }
      .grid-column-text {
        grid-row: 1;
        text-align: center;
        padding: 0 40px;
        align-self: center;
        h4 {
          margin-top: 0;
          .sans-serif-regular();
          font-size: 15px;
          text-transform: none;
        }
      }
      .grid-column-image {
        grid-row: 1;
      }
      .grid-column-left {
        grid-row: 1;
        grid-column-start: 1;
        grid-column-end: 2;
      }
      .grid-column-right {
        grid-row: 1;
        grid-column-start: 2;
        grid-column-end: 3;
      }
      &.images-row {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
  article.about-page {
    margin: 0 10px;
  }
}
@media screen and (min-width: 840px) {
  .about-page {
    .advantages {
      ul {
        li {
          line-height: 23px;
          p {
            line-height: 23px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 960px) {
  .about-page {
    .advantages {
      ul {
        li {
          line-height: 27px;
          p {
            font-size: 14px;
            line-height: 27px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .about-page {
    .advantages {
      ul {
        li {
          line-height: 30px;
          p {
            line-height: 30px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1100px) {
  .about-page {
    .advantages {
      ul {
        li {
          line-height: 32px;
          p {
            font-size: 15px;
            line-height: 32px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .about-page {
    .advantages {
      ul {
        li {
          line-height: 35px;
          p {
            line-height: 35px;
          }
        }
      }
    }
  }
}

.cgv {
  #international-shipping-costs-table {
    margin: 20px 0 10px;
  }
  .greybox {
    padding: 10px;
    background-color: @lightgrey;
    border: 1px solid @mediumgrey;
  }
}


.hero-teaser-dices {
  padding: 0 5px;
  margin: 0 0 40px;
  #dices {
    position: relative;
    width: 100%;
    padding-top: 127.9838709%;
  }
}
@media screen and (min-width: 640px) {
  .hero-teaser-dices {
    padding: 0 10px;
    #dices {
      padding-top: 33.3333333%;
    }
  }
}


.nft-collections {
  h2 {
    font-family: @serif-normal;
    text-transform: none;
  }
  .nft-galerie-teaser {
    margin: 0 0 40px;
    .imagebox {
      display: block;
      max-width: 600px;
      margin: 0 auto 20px;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0px 0px 10px -5px;
    }
  }
}
@media screen and (min-width: 1024px) {
  .nft-collections {
    p {
      font-size: 15px;
      line-height: 23px;
    }
    .nft-galerie-teaser {
      .imagebox {
        width: calc(50% - 10px);
        margin: 0 10px 20px 0;
        float: left;
      }
      .textbox {
        width: calc(50% - 10px);
        margin: 0 0 20px 10px;
        float: right;
      }
    }
  }
}

.cms-creation {
  h1 {
    font-family: @serif-normal;
    font-style: italic;
    text-transform: none;
  }
}

// new ringsize-page styles

.page-ringsizes h2 {
  text-transform: none;
}
.page-ringsizes h3 {
  font-size: 13px;
  margin: 0.5rem 0;
}

.page-ringsizes h2,
.page-ringsizes h3,
.page-ringsizes p {
  padding: 5px 5px
}

.ringsize-measuring-methods,
.ringsize-buttons,
.ringsize-table {
  &:before {
    display: block;
    margin: 0 5px 20px;
    border-top: 2px solid @lightgrey;
    content: '';
  }
}

@media screen and (min-width: 768px){
  .page-ringsizes h2,
  .page-ringsizes h3, 
  .page-ringsizes p {
    padding: 0 10px
  }
}

// section ringsize-video

.ringsize-video{
  margin-bottom: 10px;
  .text, .video {
    flex: 1;
  }
  .video-container{
    position: relative;
    padding-bottom: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
    }
  } 
}
@media screen and (min-width: 640px) {
  .ringsize-video {
    margin-bottom: 0;
  }
  .page-ringsizes .ringsize-video {
    flex-direction: row;
      .video {
        flex: 1;
        padding: 5px;
      }
      .text {
        order: 2;
        padding: 5px;
      }
  }
}
@media screen and (min-width: 768px) {
  .page-ringsizes .ringsize-video {
    .video, .text {
      padding: 10px;
    }
  }
}

//section ringsize-measuring-methods
.page-ringsizes {
  .ringsize-measuring-methods {
    .clear {
      line-height: 18px;
    }
    .method-3 {
      p {
        margin-bottom: 0;
        &:last-child {
          margin-bottom: 1em;
        }
      }
    }
  }
}

.page-ringsizes .formula-box {
  max-width: fit-content;
  padding: 11px;
  margin: 0 auto;
  background-color: @lightgold;
  font-weight: bold;
  font-size: 12px;
}

// section ringsize-table

.c-ringsize-table {
  position: relative;
  display: block;
  margin: 20px 0 60px;
  font-size: 10px;
  line-height: 18px;
  .label-row {
    box-sizing: border-box;
    padding: 7px 3px;
    border-top: none;
    border-bottom: 2px solid @mediumgrey;
    .sans-serif-bold();
    font-size: 0;
    line-height: 0;
    align-items: center;
    text-align: center;
    span{
      &:after {
        display: block;
        width: 20px;
        height: 20px;
        margin: 0 auto;
        .rounded();
        border: 1px solid @mediumgrey;
        background-image: url('../images/ringsize-flags.png');
        background-size: 700%;
        content: '';
      }
      &.diameter-size:after,
      &.circumference-size:after {
        display: none;
      }
      &.de:after {
        background-position: 0 0;
      }
      &.en:after,
      &.uk-scale:after {
        background-position: -100% 0;
      }
      &.fr:after,
      &.fr-scale:after {
        background-position: -200% 0;
      }
      &.us-scale:after {
        background-position: -300% 0;
      }
      &.it:after {
        background-position: -400% 0;
      }
      &.es:after,
      &.es-size:after {
        background-position: -500% 0;
      }
      &.nl:after,
      &.nl-size:after {
        background-position: -600% 0;
      }
    }
  }
  a {
    display: block;
    position: relative;
    margin-top: 10px;
    color: @black;
    border: 1px solid @mediumgrey;
    border-radius: 5px;
    overflow: hidden;
    &.grouped:hover {
      text-decoration: none;
      border: 1px solid @pink;
      box-shadow: 0px 0px 2px 0px @pink;
    }
    &.grouped div {
      box-sizing: border-box;
    }
    &.grouped div:first-child {
      border-top: none;
    }
    &.grouped {
      background-color: @white;
    }
    &.gold {
      background-color: lighten(@lightgold, 2.5%);
    }
  }
  .diameter-size, .circumference-size {
    font-size: 9px;
    line-height: 18px;
    white-space: nowrap;
    i {
      display: block;
      font-style: normal;
    }
  }
  .ringsize-table-row,
  .label-row {
    display: grid;
    grid-template-columns: 30px calc(50% - 60px) calc(50% - 60px) 30px 30px 30px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 8px 3px 4px;
    border-top: 1px solid @mediumgrey;
    text-align: center;
    span {
      display: block;
      &.de, &.en, &.es, &.fr, &.it, &.nl {
        display: none;
      }
    }
  }
}
.de .c-ringsize-table div span.de {
  display: block;
}
.nl .c-ringsize-table div span.nl {
  display: block;
}
.en .c-ringsize-table div span.de {
  display: block;
}
.es .c-ringsize-table div span {
  &.es, &.de{
    display: block;
  }
  &.us, &.us-scale{
    display: none;
  }
}
.fr .c-ringsize-table div span {
  &.fr, &.de{
    display: block;
  }
  &.us, &.us-scale{
    display: none;
  }
}
.it .c-ringsize-table div span {
  &.it, &.de{
    display: block;
  }
  &.us, &.us-scale{
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .sticky-table {
    .c-ringsize-table .label-row {
      z-index: 15;
    }
    .c-ringsize-table {
      .label-row {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: auto;
        padding: 8px 15px;
        background-color: @white;
      }
      .diameter-size,
      .circumference-size {
        font-size: 9px;
      }
    }
  }
}

@media screen and (min-width: 360px){
  .c-ringsize-table{
    .label-row,
    .ringsize-table-row {
      grid-template-columns: 40px calc(50% - 80px) calc(50% - 80px) 40px 40px 40px;
    }
  }
}

@media screen and (min-width: 480px) {
  .sticky-table .c-ringsize-table .label-row {
    padding: 8px 14px 8px 44px;
    .diameter-size, .circumference-size {
      font-size: 10px;
    }
  }
  .c-ringsize-table {
    .label-row {
      padding-left: 34px;
    }
    .diameter-size, .circumference-size {
      line-height: 18px;
      i {
        display: inline;
        font-style: normal;
      }
    }
    .grouped {
      padding-left: 30px;
    }
    a.grouped div {
      border-left: 1px solid @mediumgrey;
    } 
    .grouped:before{
      position: absolute;
      display: block;
      top: calc(50% - 11px);
      left: 0;
      width: 30px;
      font-family: @icon;
      content: '\f105';
      font-size: 20px;
      color: @pink;
      text-align: center;
    }
  }
}

@media screen and (min-width: 640px){
  .c-ringsize-table{
    .label-row,
    .ringsize-table-row {
      grid-template-columns: 15% 20% 20% 15% 15% 15%;
    }
  }
}

@media screen and (min-width: 768px){
  .c-ringsize-table{
    font-size: 12px;
    .diameter-size,
    .circumference-size {
      line-height: 20px;
      font-size: 12px;
      i {
        display: block;
        font-style: normal;
      }
    }
  }
}

@media screen and (min-width: 1024px){
  .c-ringsize-table {
    font-size: 13px;
    .label-row,
    .diameter-size,
    .circumference-size {
      background-color: @lightgold;
      font-size: 13px;
      line-height: 20px;
    }
    a {
      margin-top: 0;
      border-radius: 0;
    }
  }
}

// section ringsize-slider-table
.ringsize-slider {
  .tooltip-text {
    display: none;
    position: absolute;
    top: 95px;
    left: 45px;
    z-index: 3;
    width: 200px;
    padding: 20px;
    margin: 10px 5px;
    border: 1px solid @mediumgrey;
    border-radius: 5px;
    background: @white;
    color: @darkgrey;
    font-size: 11px;
    box-shadow: 0 0 3px @mediumgrey;
    &:before, &:after {
      position: absolute;
      content: "";
      display: block;
    }
    &:before {
      right: 120px;
      z-index: 4;
      top: 76px;
      border-top: 10px solid @white;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
    &:after {
      right: calc(120px - 1px);
      z-index: 3;
      top: 76px;
      border-top: 11px solid @mediumgrey;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
    }
    a {
      .sans-serif-bold();
    }
    .close {
      top: 5px;
      &:after {
        width: 16px;
        height: 16px;
        line-height: 18px;
        content: 'X';
      }
    }
  }
  .c-ringsize-slider-table {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    font-size: 10px;
    .label-row,
    .value-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 48px 30px 30px 30px 30px;
      grid-column-start: 1;
      grid-row-start: 1;
      margin: 0;
      span {
        box-sizing: border-box;
        position: relative;
        i {
          display: inline;
          font-style: normal;
        }
      }
    }
    .label-row {
      z-index: 2;
      box-sizing: border-box;
      .sans-serif-regular();
      align-items: center;
      text-align: center;
      .diameter-size,
      .circumference-size {
        background-color: @lightgold;
        border: 1px solid @mediumgrey;
        white-space: nowrap;
        input {
          border: none;
          border-bottom: 2px solid @mediumgrey;
          font-size: 10px;
          text-align: center;
          &::placeholder {
            color: @mediumgrey;
          }
        }
        i {
          &:after {
            z-index: 2;
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-left: 3px;
            border-radius: 100%;
            background-color: @gold;
            .sans-serif-regular();
            font-size: 11px;
            line-height: 17px;
            text-align: center;
            color: @white;
            content: 'i';
          }
        }
        .tooltip-link {
          color: @darkgrey;
          &:link, &:visited, &:hover, &:active {
            text-decoration: none;
          }
          &:focus + .ringsize-slider .tooltip-text {
            display: block;
          }
        }
      }
      .circumference-size {
        border-left: none;
      }
      .de-size, .en-size, .es-size, .fr-size, .it-size, .nl-size,
      .fr-scale, .uk-scale, .us-scale {
        display: flex;
        align-items: center;
        border: 1px solid @mediumgrey;
        border-bottom: none;
      }
      .de-size, .en-size, .es-size, .fr-size, .it-size, .nl-size {
        display: none;
      }
      .us-scale {
        border-bottom: 1px solid @mediumgrey;
      }
      span {
        padding: 5px;
        &:before {
          display: block;
          width: 17px;
          height: 17px;
          margin-right: 5px;
          .rounded();
          border: 1px solid @mediumgrey;
          background-image: url('../images/ringsize-flags.png');
          background-size: 700%;
          content: '';
        }
        &.diameter-size:before,
        &.circumference-size:before {
          display: none;
        }
        &.de:before,
        &.de-size:before {
          background-position: 0 0;
        }
        &.en-size:before,
        &.uk-scale:before {
          background-position: -100% 0;
        }
        &.fr-size:before,
        &.fr-scale:before {
          background-position: -200% 0;
        }
        &.us-scale:before {
          background-position: -300% 0;
        }
        &.it-size:before {
          background-position: -400% 0;
        }
        &.es-size:before {
          background-position: -500% 0;
        }
        &.nl-size:before {
          background-position: -600% 0;
        }
      }
    }
    .value-row {
      z-index: 1;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        &.de, &.en, &.es, &.fr, &.it, &.nl {
          display: none;
        }
      }
      .ringsize-diameter {
        border: 1px solid @mediumgrey;
        border-top: none;
      }
      .ringsize-circumference {
        border-right: 1px solid @mediumgrey;
        border-bottom: 1px solid @mediumgrey;      
      }
      .de, .fr, .nl, .es, .it, .en,
      .fr-scale, .uk-scale, .us-scale {
        border-top: 1px solid @mediumgrey;
        border-right: 1px solid @mediumgrey;
      }
      .us-scale {
        border-bottom: 1px solid @mediumgrey;
      }
    }
  }
  .ringsize-slider-info {
    margin: 0;
    padding: 5px;
    border-left: 1px solid @mediumgrey;
    border-right: 1px solid @mediumgrey;
  }
  #ringsize-slider {
    height: 60px;
    padding: 20px 5px 0;
    margin: 0;
    border: 1px solid @mediumgrey;
    border-top: none;
    border-bottom: none;
  }
  .slider-heading,
  .slider-hint {
    display: block;
  }
  .slider-heading {
    .sans-serif-bold();
  }
  #ringsize-slider-track {
    height: 60px;
    position: relative;
    width: calc( 100% - 30px );
    background-image: url(../images/ringsize-slider-bg.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
  }
  #ringsize-slider-handle {
    position: absolute;
    bottom: 9px;
    span {
      position: relative;
      left: 8px;
    }
    &-bar {
      display: block;
      width: 26px;
      height: 26px;
      margin: 8px 0 0 0;
      cursor: col-resize;
      &:before, &:after {
        display: block;
        position: absolute;
        .rounded;
        background-color: @gold;
        content: '';
      }
      &:before {
        width: 12px;
        height: 12px;
        top: -1px;
        left: 7px;
      }
      &:after {
        width: 26px;
        height: 26px;
        top: -8px;
        left: 0;
        opacity: .5;
        .shadow();
      }
    }
  }
  .ringsize-slider-button {
    margin: 0 0 20px;
    padding: 0 0 20px 5px;
    text-align: center;
    border: 1px solid @mediumgrey;
    border-top: none;
  }
}

.de .ringsize-slider .c-ringsize-slider-table div span.de,
.de .ringsize-slider .c-ringsize-slider-table div span.de-size,
.es .ringsize-slider .c-ringsize-slider-table div span.es,
.es .ringsize-slider .c-ringsize-slider-table div span.es-size,
.fr .ringsize-slider .c-ringsize-slider-table div span.fr,
.fr .ringsize-slider .c-ringsize-slider-table div span.fr-size,
.it .ringsize-slider .c-ringsize-slider-table div span.it,
.it .ringsize-slider .c-ringsize-slider-table div span.it-size,
.nl .ringsize-slider .c-ringsize-slider-table div span.nl,
.nl .ringsize-slider .c-ringsize-slider-table div span.nl-size,
.en .ringsize-slider .c-ringsize-slider-table div span.de,
.en .ringsize-slider .c-ringsize-slider-table div span.de-size {
  display: flex;
}

@media screen and (min-width: 360px) {
  .ringsize-slider {
    .tooltip-text {
      top: 2%;
      left: 18%;
    }
  }
}

@media screen and (min-width: 480px) {
  .ringsize-slider {
    .tooltip-text {
      top: 1.5%;
      left: 26%;
    }
    .c-ringsize-slider-table {
      font-size: 12px;
      .label-row {
        .circumference-size, .diameter-size {
          input {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 639px){
  .c-ringsize-slider-table {
    .label-row {
      .diameter-size {
        grid-column: ~"1/3";
        grid-row: ~"1/2";
      }
      .circumference-size {
        grid-column: ~"3/5";
        grid-row: ~"1/2";
      }
      .de-size,
      .fr-size,
      .nl-size,
      .es-size,
      .it-size,
      .en-size {
        grid-column: ~"1/3";
        grid-row: ~"3/4";
      }
      .fr-scale{
        grid-column: ~"1/3";
        grid-row: ~"4/5";
      }
      .uk-scale{
        grid-column: ~"1/3";
        grid-row: ~"5/6";
      }
      .us-scale{
        grid-column: ~"1/3";
        grid-row: ~"6/7";
      }
    }
    .value-row {
      .ringsize-diameter {
        grid-column: ~"1/3";
        grid-row: ~"2/3";
        margin-bottom: 10px;
      }
      .ringsize-circumference {
        grid-column: ~"3/5";
        grid-row: ~"2/3";
        margin-bottom: 10px;
      }
      .de,
      .fr,
      .nl,
      .es,
      .it,
      .en {
        grid-column: ~"3/5";
        grid-row: ~"3/4";
      }
      .fr-scale{
        grid-column: ~"3/5";
        grid-row: ~"4/5";
      }
      .uk-scale{
        grid-column: ~"3/5";
        grid-row: ~"5/6";
      }
      .us-scale{
        grid-column: ~"3/5";
        grid-row: ~"6/7";
      }
    }
  }
}

@media screen and (min-width: 640px){
  .ringsize-slider {
    .c-ringsize-slider-table {
      .label-row,
      .value-row {
        grid-template-columns: 15% 20% 20% 15% 15% 15%;
        grid-template-rows: 1fr 1fr;
      }
      .label-row {
        span {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column-reverse;
          padding: 10px;
          background-color: @lightgold;
          &.diameter-size,
          &.circumference-size {
            flex-direction: column;
            align-items: center;
          }
          i {
            display: block;
          }
          &:before{
            display: block;
            width: 20px;
            height: 20px;
            margin: 0 auto;
          }
        }
        .diameter-size {
          grid-column: ~"2/3";
          grid-row: ~"1/2";
        }
        .circumference-size {
          grid-column: ~"3/4";
          grid-row: ~"1/2";
        }
        .de-size,
        .fr-size,
        .nl-size,
        .es-size,
        .it-size,
        .en-size {
          grid-column: ~"1/2";
          grid-row: ~"1/2";
          border-right: none;
        }
        .fr-scale{
          grid-column: ~"4/5";
          grid-row: ~"1/2";
          border-left: none;
        }
        .uk-scale{
          grid-column: ~"5/6";
          grid-row: ~"1/2";
          border-left: none;
        }
        .us-scale{
          grid-column: ~"6/7";
          grid-row: ~"1/2";
          border-left: none;
          border-bottom: none;
        }
      }
      .value-row {
        .ringsize-diameter {
          grid-column: ~"2/3";
          grid-row: ~"2/3";
        }
        .ringsize-circumference {
          grid-column: ~"3/4";
          grid-row: ~"2/3";
        }
        .de,
        .fr,
        .nl,
        .es,
        .it,
        .en {
          grid-column: ~"1/2";
          grid-row: ~"2/3";
          border-left: 1px solid @mediumgrey;
          border-bottom: 1px solid @mediumgrey;
          border-right: none;
        }
        .fr-scale{
          grid-column: ~"4/5";
          grid-row: ~"2/3";
          border-bottom: 1px solid @mediumgrey;
        }
        .uk-scale{
          grid-column: ~"5/6";
          grid-row: ~"2/3";
          border-bottom: 1px solid @mediumgrey;
        }
        .us-scale{
          grid-column: ~"6/7";
          grid-row: ~"2/3";
        }
      }
    }
  }
}
@media screen and (min-width: 768px){
  .ringsize-slider {
    .tooltip-text {
      left: 21%;
      font-size: 12px;
    }
    .c-ringsize-slider-table {
      #ringsize-slider {
        padding: 30px 5px 0;
      }
      #ringsize-slider-track {
        height: 20px;
      }
      #ringsize-slider-handle {
        bottom: 34px;
      }
      #ringsize-slider-handle-bar {
        &:before {
          width: 14px;
          height: 14px;
          top: 3px;
          left: 5px;
        }
        &:after {
          width: 30px;
          height: 30px;
          top: -5px;
          left: -3px;
        }
      }
    }
  }
}
@media screen and (min-width: 1024px){
  .ringsize-slider {
    .tooltip-text {
      top: 1%;
      left: 25%;
    }
    .c-ringsize-slider-table {
      font-size: 13px;
      .label-row {
        span {
          display: block;
          input {
            font-size: 13px;
          }
          i {
            display: inline;
          }
        }
      }
    }
  }
}

//heading swap mobile/desktop

h1 {
  text-align: center;
  span {
  .sans-serif-regular();
    &.h1-mobile {
      display: inline;
    }
    &.h1-desktop {
      display: none;
    }
  }
}

@media screen and (min-width: 480px) {
  h1 {
    text-align: left;
    span {
      &.h1-mobile {
        display: none;
      }
      &.h1-desktop {
        display: inline;
      }
    }
  }
}

// italian ringsize styles

.es, .fr, .it {
  .c-ringsize-table {
    .label-row,
    .ringsize-table-row {
      grid-template-columns: 30px 30px calc(50% - 60px) calc(50% - 60px) 30px 30px;
    }
    .circumference-size,
    .diameter-size {
    font-size: 8px;
    }
  } 
  .ringsize-slider .c-ringsize-slider-table .label-row,
  .ringsize-slider .c-ringsize-slider-table .value-row {
    grid-template-rows: 1fr 48px 30px 30px 30px 30px;
    .de-size, .de {
      display: flex;
    }
  }
}

@media screen and (max-width: 639px){
  .es, .fr, .it {
    .c-ringsize-slider-table {
      .label-row {
        .es-size,
        .fr-size,
        .it-size {
          grid-row: ~"3/4";
        }
        .de-size {
          grid-row: ~"4/5";
        }
        .fr-scale{
          grid-row: ~"5/6";
        }
        .uk-scale{
          grid-row: ~"6/7";
          border-bottom: 1px solid @mediumgrey;
        }
        .us-scale{
          display: none;
        }
      }
      .value-row {
        .es, .fr, .it {
          grid-row: ~"3/4";
        }
        .de {
          grid-row: ~"4/5";
        }
        .fr-scale{
          grid-row: ~"5/6";
        }
        .uk-scale{
          grid-row: ~"6/7";
          border-bottom: 1px solid @mediumgrey;
        }
        .us-scale{
          display: none;
        }
      }
    }
  }
}

@media screen and (min-width: 360px) {
  .es, .fr, .it {
    .c-ringsize-table {
      .label-row,
      .ringsize-table-row {
        grid-template-columns: 40px 40px calc(50% - 80px) calc(50% - 80px) 40px 40px;
      }
    }
  }
}

@media screen and (min-width: 480px) {
  .es, .fr, .it {
    .c-ringsize-table {
      .circumference-size,
      .diameter-size {
        font-size: 9px;
      }
    }
  } 
}

@media screen and (min-width: 640px) {
  .es, .fr, .it {
    .c-ringsize-table {
      .label-row,
      .ringsize-table-row {
        grid-template-columns: 15% 15% 20% 20% 15% 15%;
      }
    } 
    .ringsize-slider {
      .c-ringsize-slider-table {
        .label-row,
        .value-row {
          grid-template-columns: 15% 15% 20% 20% 15% 15%;
          grid-template-rows: 1fr 1fr;
        }
        .label-row {
          .it-size {
            grid-column: ~"1/2";   
          }
          .de-size {
            grid-column: ~"2/3";   
          }
          .diameter-size {
            grid-column: ~"3/4";
          }
          .circumference-size {
            grid-column: ~"4/5";
          }
          .fr-scale {
            grid-column: ~"5/6";
          }
          .uk-scale {
            grid-column: ~"6/7";
          }
          .us-scale {
            display: none;
          }
        }
        .value-row {
          .es, .fr, .it {
            grid-column: ~"1/2";   
          }
          .de {
            grid-column: ~"2/3";   
          }
          .ringsize-diameter {
            grid-column: ~"3/4";
          }
          .ringsize-circumference {
            grid-column: ~"4/5";
          }
          .fr-scale {
            grid-column: ~"5/6";
          }
          .uk-scale {
            grid-column: ~"6/7";
          }
          .us-scale {
            display: none;
          }
        }
      }
    } 
  }
}

@media screen and (min-width: 768px) {
  .es, .fr, .it {
    .c-ringsize-table {
      .circumference-size,
      .diameter-size {
        font-size: 10px;
      }
    }
  }  
}

@media screen and (min-width: 1024px) {
  .es, .fr, .it {
    .c-ringsize-table {
      .circumference-size,
      .diameter-size {
        font-size: 13px;
        line-height: 20px;
      }
    }
  } 
}

// howtobid styles

.howtobid {
  .onpagenav-mobile {
    ul {
      padding: 4px;
    }
  }
  section {
    h2 {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 70px;
      padding: 0 8px 0 96px;
      margin: 32px 0 10px;
      text-transform: none;
      color: @white;
      background-color: #016F97;
      text-shadow: 0px 0px 2px black;

      &:before {
        position: absolute;
        left: 8px;
        top: 24px;
        font-family: @icon;
      }
      span {
        display: block;
        text-indent: -26px;
        .sans-serif-bold();
      }      
      i {
        display: inline-block;
        box-sizing: border-box;
        text-align: right;
        padding-right: 8px;
        width: 26px;
        text-indent: 0;
        .sans-serif-bold();
        font-style: normal;
      }
    }
    &.section-einkaufen {
      h2 {
        &:before {
          top: 22px;
          content: '\f219';
          font-size: 46px;
        }
      }
    }
    &.section-telefon {
      h2 {
        &:before {
          content: '\e91f';
          font-size: 52px;
        }
      }
    }
    &.section-bidding-agent {
      h2 {
        &:before {
          content: '\e91e';
          font-size: 58px;
        }
      }
    }
    &.section-juwelo-app {
      h2 {
        &:before {
          content: '\e91c';
          font-size: 54px;
        }
      }
    }
    &.section-bezahlung {
      h2 {
        &:before {
          content: '\e91b';
          font-size: 48px;
        }
      }
    }
    &.section-versand {
      h2 {
        &:before {
          content: '\e9b0';
          font-size: 44px;
          left: 14px;        
        }
      }
    }
    &.section-fragen {
      h2 {
        &:before {
          content: '\e91d';
          font-size: 56px;
        }
      }
    }
    .telefon-box {
      display: grid;
      grid-gap: 8px;
      grid-template-columns: 1fr 1fr;
      box-sizing: border-box;
      padding: 8px;
      margin: 8px 0;
      border: 1px solid @darkgrey;
      .number {
        grid-column: span 2;
        grid-row: ~"1/2";
        .sans-serif-regular();
        font-size: 19px;
      }
      .info {
        font-size: 15px;
        grid-column: span 2;
      }
      .flag {
        display: flex;
        align-items: center;
        font-size: 13px;
        text-indent: 32px;
        span {
          white-space: nowrap;
        }
      }
      &.ita {
        .number {
          .sans-serif-bold();
          font-size: 39px;
          line-height: 39px;
        }
        &.verde {
          .number {
            &:before {
              font-family: @icon;
              color: @green;
              content: '\f095';
            }
          }
        }
        &.mobile {
          .number {
            &:before {
              font-family: @icon;
              color: @lightpink;
              content: '\f10b';
            }
          }
        }
      }
      &.nl {
        .info {
          font-size: 12px;
          grid-column: span 1;
          grid-row: ~"2/3";
        }
        .number {
          grid-column: span 1;
        }
      }
      &.es {
        .info {
          font-size: 12px;
          grid-column: span 1;
        }
        .number {
          grid-column: span 1;
        }
      }
    }
  }
}

@media screen and (min-width: 380px) {
  .howtobid {
    section {
      .telefon-box {
        .flag {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .howtobid {
    .onpagenav-mobile {
      display: none;
    }
    section {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      min-height: 150px;
      padding-left: 166px;
      margin: 24px 0 12px;
      &.section-einkaufen {
        margin: 0 0 12px;
      }
      &:before {
        position: absolute;
        top: 16px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 150px;
        height: 150px;
        background-color: #016F97;
        color: white;
        font-family: @icon;
      }
      &.section-einkaufen {
        &:before {
          content: '\f219';
          font-size: 72px;
        }
      }
      &.section-telefon {
        &:before {
          content: '\e91f';
          font-size: 72px;
        }
      }
      &.section-bidding-agent {
        &:before {
          content: '\e91e';
          font-size: 80px;
        }
      }
      &.section-juwelo-app {
        &:before {
          content: '\e91c';
          font-size: 80px;
        }
      }
      &.section-bezahlung {
        &:before {
          content: '\e91b';
          font-size: 68px;
        }
      }
      &.section-versand {
        &:before {
          content: '\e9b0';
          font-size: 62px;
        }
      }
      &.section-fragen {
        &:before {
          content: '\e91d';
          font-size: 80px;
        }
      }
      h2 {
        display: block;
        min-height: 0;
        padding: 0;
        margin: 16px 0 12px;
        font-size: 18px;
        color: @black;
        background: none !important;
        text-shadow: none;
        &:before {
          content: none !important;
        }
        span {
          .sans-serif-regular();
          text-indent: 0 !important;
        }
        i {
          .sans-serif-regular();
          display: inline;
          padding-right: 0;
          margin-right: 4px;
        }
      }
      .telefon-boxes {
        display: flex;
        width: 100%;
        .telefon-box {
          grid-auto-rows: min-content;
          padding: 16px;
          .number {
            font-size: 19px;
          }
          &.ger {
            grid-template-columns: minmax(140px, auto);
            width: 25%;
            .number, .info {
              grid-column: span 1;
            }
          }
          &.int {
            grid-template-columns: minmax(104px, auto) minmax(162px, auto) minmax(80px, auto);
            width: 75%;
            margin-left: 16px;
            .number, .info {
              grid-column: span 3;
            }
          }
          &.ita {
            flex: 0 0 calc(50% - 8px);
            &.verde {
              margin-right: 16px;
            }
            .number {
              font-size: 39px;
            }
          }
          &.nl {
            width: 75%;
          }
          &.es, &.de {
            width: 50%;
          }
          &.es {
            margin: 8px 16px 8px 0px;
          }
        }
      }
    }
    section:last-child {
      margin: 24px 0 42px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .howtobid {
    section {
      .telefon-boxes {
        .telefon-box {
          grid-gap: 12px;
          .number {
            font-size: 20px;
          }
          .info {
            font-size: 15px;
          }
          .flag {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.c-astrologie {
  display: grid;
  margin: 30px 0;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  li {
    text-align: center;
    h2 {
      margin: 0 0 10px;
      font-size: 22px;
      line-height: 22px;
    }
    h3 {
      margin: 0 0 10px;
      font-size: 15px;
      line-height: 15px;
    }
    img {
      margin: 0 auto;
      border: 20px solid #fff;
      box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
    }
  }
}
@media screen and (min-width: 640px) {
  .c-astrologie {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 768px) {
  .c-astrologie {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .c-astrologie {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}


// new CMS styles

.col.textpage {
  h1 {
    font-size: 20px;
    text-transform: none;
    text-align: center;
    margin: 10px 0 15px;
  }

  h2, p {
    font-size: 14px;
  }

  .col-3-set {
    .col {
      img {
        margin: 0 auto;
      }
    }
  }
}


@media screen and (min-width: 768px) {
  .col.textpage {
    max-width: 960px;
    float: none;
    margin: 0 auto;
    h1 {
      font-size: 28px;
      margin: 0 0 15px;
      line-height: 1.2em;
    }
    h2 {
      font-size: 22px;
      text-shadow: none;
    }
    p {
      font-size: 16px;
    }
    .col-3-set {
      .col {
        img {
          margin: 0;
        }
      }
    }
  }
}

.juwelo-vouchers {
  text-align: center;
  h2 {
    margin: 40px 0 20px;
    padding-top: 20px;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    border-top: 2px solid @mediumgrey;
    &:first-child {
      margin: 0 0 20px;
      padding-top: 0;
      border-top: none;
    }
  }
  .voucher {
    box-sizing: border-box;
    margin: 0 0 20px;
    padding: 20px 0 0;
    border-top: 1px solid @mediumgrey;
    &:first-child {
      border-top: none;
    }
    h3 {
      margin: 0 0 10px;
      font-size: 15px;
      line-height: 15px;
    }
    h4 {
      .sans-serif-bold();
      font-size: 13px;
      text-transform: none;
    }
    p {
      margin: 0;
      font-size: 13px;
      b {
        .sans-serif-bold();
      }
      &.copy-voucher-code {
        display: inline-block;
        box-sizing: border-box;
        margin: 0 0 20px;
        padding: 0 10px;
        text-transform: none;
        .sans-serif-regular();
        font-size: 13px;
        line-height: 30px;
        border: 1px solid @mediumgrey;
        background-color: @white;
        span {
          .sans-serif-bold();
          &:after {
            display: inline-block;
            padding: 0 0 0 5px;
            font-family: @icon;
            content: '\f24d';
            transform: scaleY(-1);
          }
        }
        &.copied:before {
          display: none;
        }
      }
      &.newsletter {
        margin: 20px 0;
      }
      &.restrictions {
        margin: 20px 0 0;
        font-size: 11px;
        span {
          display: block;
        }
      }
    }
    a {
      display: block;
      text-decoration: underline;
    }
    .copied-msg {
      position: relative;
      display: none;
      margin: 0 0 20px;
      padding: 0 10px;
      background-color: @green;
      color: @white;
      line-height: 30px;
      b {
        padding: 0 10px;
        .sans-serif-bold();
      }
      .close {
        right: 0;
        top: 0;
        &:after {
          width: 30px;
          text-align: center;
          font-family: @icon;
          font-size: 20px;
          line-height: 30px;
          content: '\e901';
          background-color: transparent;
        }
      }
    }
    p.copied + .copied-msg {
      display: block;
    }
    .card {
      position: relative;
      display: block;
      box-sizing: border-box;
      max-width: 400px;
      margin: 20px auto;
      padding: 0 30px 56.85%;
      border-radius: 5px;
      text-align: center;
      background-image: url('../images/bg-voucher-card.png');
      background-size: 150px;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #d7aaae;
      &.no-code {
        h4 {
          line-height: 20px;
        }
      }
      .voucher-value {
        position: absolute;
        top: calc(50% - 50px);
        left: calc(50% - 50px);
        display: block;
        width: 100px;
        height: 100px;
        margin: 0 auto 20px;
        box-sizing: border-box;
        border-radius: 100%;
        background-color: @black;
        color: @white;
        font-family: @serif-normal;
        font-size: 36px;
        line-height: 100px;
        font-style: italic;
      }
      &.free-shipping {
        .voucher-value {
          padding: 35px 0;
          font-size: 13px;
          line-height: 15px;
        }
      }
    }
  }
}
@media screen and (min-width: 440px) {
  .juwelo-vouchers {
    .voucher {
      .card {
        width: 320px;
        height: 180px;
        padding: 0 30px;
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .juwelo-vouchers {
    text-align: left;
    .voucher {
      display: grid;
      max-width: 860px;
      grid-template-columns: 320px auto;
      grid-column-gap: 20px;
      .card {
        margin: 0;
      }
      h4.newsletter {
        padding: 0;
      }
    }
  }
}


.cms-page-view .influencer-page {
  padding: 0 5px;
  .section-header {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content auto;
    h1 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
      padding: 0;
    }
    picture {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    ul {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
      margin: 0.5em 0 0;
      li {
        margin: 0 0 0.5em;
      }
    }
  }
  h2 {
    clear: both;
  }
  ol.numbered {
    padding-left: 20px;
    list-style: decimal;
  }
  ul.bulleted {
    padding-left: 20px;
    list-style: disc;
  }
  .grid-row.images-row {
    clear: both;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    &.itemcount-2 {
      grid-template-columns: 1fr;
    }
  }
  .grid-row.image-text {
    .text {
      a.button {
        margin: 20px auto 0;
        border-radius: 5px;
      }
    }
  }
  .small-image {
    max-width: 400px;
    margin: 40px auto;
    .caption {
      display: none;
    }
  }
  .wide-image {
    max-width: 960px;
    margin: 40px auto;
    .caption {
      display: none;
    }
  }
}
@media screen and (min-width: 640px) {
  .cms-page-view .influencer-page {
    section {
      margin: 0 0 60px;
    }
    .section-header {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: max-content auto auto;
      h1 {
        margin: 0 0 1em;
        padding: 0 0 1em;
        border-bottom: 1px solid @mediumgrey;
        line-height: 1em;
      }
      picture {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 4;
      }
      ul {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
      }
    }
    .grid-row.images-row {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      &.itemcount-2 {
        grid-template-columns: 1fr 1fr;
      }
    }
    .grid-row.image-text {
      clear: both;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
      align-items: center;
      .text {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
        text-align: center;
        padding: 40px;
      }
      picture {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
        img {
          max-width: 400px;
        }
      }
    }
    .small-image {
      max-width: 50%;
      &.left {
        float: left;
        margin: 0 20px 40px 0;
      }
      &.right {
        float: right;
        margin: 40px 0 40px 20px;
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .cms-page-view .influencer-page {
    padding: 0 10px;
    font-size: 120%;
    h2, h3 {
      font-size: 120%;
    }
    .grid-row.images-row,
    .grid-row.image-text {
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }
    .grid-row.image-text {
      max-width: 800px;
      margin: 40px auto;
    }
  }
}

.cms-brand-page {
  padding: 0 5px;
  h2 {
    margin: 2em 0 1em;
    text-align: center;
    text-transform: none;
  }
  .grid-container {
    margin: 0 0 20px;
    .grid-item {
      margin: 0 0 10px;
    }
  }
}
@media screen and (min-width: 640px) {
  .cms-brand-page {
    .grid-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
      align-items: center;
      .grid-item {
        margin: 0;
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .cms-brand-page {
    padding: 0 10px;
    .grid-container {
      grid-column-gap: 20px;
    }
  }
}