// common
.landingpage {
  section {
    margin: 0 5px 30px;

    h2 {
      margin: 0 0 20px !important;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: .02em;
      text-align: center;
      text-transform: none;
    }

    .button {
      border-radius: 5px;

      &.white {
        background-color: transparent;
      }
    }

    .text {
      text-align: center;

      h3 {
        .sans-serif-bold();
        font-size: 15px;
        line-height: 20px;
      }

      p {
        margin: 1em 0 0;
        font-size: 13px;
        line-height: 18px;

        &:first-child {
          margin: 0;
        }
      }
    }

  }

  .clear.decorative {
    margin: 0 5px 20px;
  }
}
@media screen and (max-width: 360px) {
  .landingpage .c-slider {
    ul li .products-grid-item-wrapper .product-link-image .product-link-details .product-name {
      display: none;
    }
  }
}
@media screen and (min-width: 359px) and (max-width: 400px){
  .landingpage .c-slider {
    ul li .products-grid-item-wrapper .product-link-image .product-link-details .product-name {
      max-height: 30px;
      overflow: hidden;
    }
  }
}
@media screen and (min-width: 640px) {
  .landingpage {
    section {
      .text {
        h3 {
          font-size: 18px;
          line-height: 25px;
        }

        p {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .landingpage {
    section {
      h2 {
        font-size: 22px;
        line-height: 22px;
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .landingpage {
    section {
      margin: 0 10px 40px;
    }

    .clear.decorative {
      margin: 0 10px 30px;
    }
  }
}


// bubbles
.landingpage .bubbles-banner {
  position: relative;
  height: 100px;
  padding: 5px 30px;

  ul {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    overflow-x: auto;
    padding: 5px 0;
    li {
      flex: 0 0 100px;
      margin: 0;

      a {
        display: block;
        line-height: 20px;
        text-align: center;
        color: @darkgrey;
        user-select: none;

        picture {
          display: block;
          width: 75px;
          height: 75px;
          margin: 0 auto;
          border-radius: 100%;

          img {
            border-radius: 100%;
          }
        }

        span {
          display: block;
          padding: 5px 0 0;
          font-size: 11px;
        }
      }
    }
  }

  .prev,
  .next {
    top: 50%;
  }
}
@media screen and (min-width: 520px) {
  .landingpage .bubbles-banner {
    &.bubblecount-4 {
      ul {
        justify-content: center;
      }

      .prev,
      .next {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 620px) {
  .landingpage .bubbles-banner {
    &.bubblecount-5 {
      ul {
        justify-content: center;
      }

      .prev,
      .next {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 720px) {
  .landingpage .bubbles-banner {
    &.bubblecount-6 {
      ul {
        justify-content: center;
      }

      .prev,
      .next {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 820px) {
  .landingpage .bubbles-banner {
    &.bubblecount-7 {
      ul {
        justify-content: center;
      }

      .prev,
      .next {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 920px) {
  .landingpage .bubbles-banner {
    &.bubblecount-8 {
      ul {
        justify-content: center;
      }

      .prev,
      .next {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 1020px) and (max-width: 1023px) {
  .landingpage .bubbles-banner {
    &.bubblecount-9 {
      ul {
        justify-content: center;
      }

      .prev,
      .next {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .landingpage .bubbles-banner {
    padding: 20px 30px 10px;
    ul {
      li {
        flex: 0 0 120px;
        a {
          span {
            font-size: 13px;
          }
          &:hover picture {
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
}


// brands
.landingpage .landingpage-brands {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  h2 {
    margin: 0 !important;
  }

  .brands {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    .brand-1 {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .brand-2 {
      grid-column-start: 1;
      grid-column-end: 2;
    }

    .brand-3 {
      grid-column-start: 2;
      grid-column-end: 3;
    }

    .brand-4 {
      grid-column-start: 1;
      grid-column-end: 2;
    }

    .brand-5 {
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }
}
@media screen and (min-width: 640px) {
  .landingpage .landingpage-brands {
    .brands {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;

      .brand-1 {
        position: relative;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;

        picture {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;

          img {
            width: auto;
            height: 100%;
          }
        }
      }

      .brand-2 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 2;
      }

      .brand-3 {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 2;
      }

      .brand-4 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;
      }

      .brand-5 {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 2;
        grid-row-end: 3;
      }
    }
  }
}


// product facet slider
.landingpage .landingpage-product-facet {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  h2 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    margin: 0 !important;
  }

  picture {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .image-overlay {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    position: relative;
    h3 {
      position: absolute;
      top: 10px;
      margin: 0;
      .serif-regular();
      font-style: italic;
      font-size: 15px;
      line-height: 20px;
    }
    .button {
      position: absolute;
      bottom: 10px;
      padding: 3px 7px;
      font-size: 13px;
      line-height: 14px;
      &:after {
        padding-left: 5px;
        .icon();
        content: '\f105';
        vertical-align: baseline;
      }
    }
    &.bright {
      color: @white;
      .button {
        color: @white;
        border-color: @white;
      }
    }
    &.dark {
      color: @black;
      .button {
        color: @black;
        border-color: @black;
      }
    }
    &.left {
      text-align: left;
      h3 {
        left: 10px;
      }
      .button {
        left: 10px;
      }
    }
    &.right {
      text-align: right;
      h3 {
        right: 15px;
      }
      .button {
        right: 15px;
      }
    }
  }

  .products-slider {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0 0 30px;
    padding: 0 30px;
    ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      scroll-behavior: smooth;
      scrollbar-width: none;

      li {
        flex: 1 0 50%;
        scroll-snap-align: start;
        margin: 0;
      }
    }
  }

  .text {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 5;
  }
}
@media screen and (min-width: 400px) {
  .landingpage .landingpage-product-facet {
    .image-overlay {
      h3 {
        font-size: 18px;
        line-height: 22px;
      }
      .button {
        font-size: 13px;
        line-height: 16px;
        &:after {
          padding-left: 6px;
          vertical-align: text-top;
        }
      }
    }
  }
}
@media screen and (min-width: 540px) {
  .landingpage .landingpage-product-facet {
    .image-overlay {
      h3 {
        top: 15px;
        font-size: 20px;
        line-height: 25px;
      }
      .button {
        bottom: 15px;
        padding: 5px 10px;
        font-size: 15px;
        line-height: 20px;
        &:after {
          padding-left: 7px;
          vertical-align: text-top;
        }
      }
      &.left {
        h3 {
          left: 15px;
        }
        .button {
          left: 15px;
        }
      }
      &.right {
        h3 {
          right: 15px;
        }
        .button {
          right: 15px;
        }
      }
    }
  }
}
@media screen and (min-width: 640px) {
  .landingpage .landingpage-product-facet {
    .products-slider {
      ul {
        li {
          flex: 1 0 33.3333333%;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .landingpage .landingpage-product-facet {
    .products-slider {
      ul {
        li {
          flex: 1 0 25%;
        }
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .landingpage .landingpage-product-facet {
    .image-overlay {
      h3 {
        top: 20px;
        font-size: 25px;
        line-height: 30px;
      }
      &.left {
        h3 {
          left: 20px;
        }
        .button {
          left: 20px;
          bottom: 20px;
        }
      }
      &.right {
        h3 {
          right: 20px;
        }
        .button {
          right: 20px;
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .landingpage .landingpage-product-facet {
    grid-template-columns: 22% 78%;
    grid-template-rows: auto auto auto;
    align-items: self-start;

    h2 {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    picture {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    .image-overlay {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
      padding-top: 150%;
      &.left,
      &.right {
        text-align: left;
        h3 {
          top: 10px;
          left: 10px;
          right: 10px;
        }
        .button {
          left: 10px;
          right: auto;
          bottom: 10px;
        }
      }
    }

    .products-slider {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    .text {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    &.right {
      grid-template-columns: 78% 22%;

      picture {
        grid-column-start: 2;
        grid-column-end: 3;
      }

      .image-overlay {
        grid-column-start: 2;
        grid-column-end: 3;
      }

      .products-slider {
        grid-column-start: 1;
        grid-column-end: 2;
      }
    }
  }
}


// product slider
.landingpage .landingpage-product-slider {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  align-items: center;

  h2 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    margin: 0 !important;
  }

  .products-slider {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    display: block;
    position: relative;
    overflow: hidden;
    padding: 0 20px;

    ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      scroll-behavior: smooth;
      scrollbar-width: none;

      li {
        flex: 1 0 50%;
        scroll-snap-align: start;
        margin: 0;
      }
    }
  }

  .text {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
  }
}
@media screen and (min-width: 640px) {
  .landingpage .landingpage-product-slider {
    .products-slider {
      ul {
        li {
          flex: 1 0 33.3333333%;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .landingpage .landingpage-product-slider {
    h2 {
      font-size: 22px;
      line-height: 22px;
    }

    .products-slider {
      ul {
        li {
          flex: 1 0 25%;
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .landingpage .landingpage-product-slider {
    .products-slider {
      ul {
        li {
          flex: 1 0 25%;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .landingpage .landingpage-product-slider {
    .products-slider {
      ul {
        li {
          flex: 1 0 20%;
        }
      }
    }
  }
}


// decorative
.landingpage .decorative-banner {
  display: grid;

  h2 {
    margin: 0 0 20px !important;
  }

  .images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 5px;

    .image-1 {
      position: relative;
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        content: '';
        background: rgba(242, 242, 242, .9);
      }
    }

    .image-2 {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    .image-3 {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    .image-4 {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 5;
    }

    .image-5 {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    .image-6 {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 4;
      grid-row-end: 5;
    }

    .text {
      position: relative;
      z-index: 2;
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
      align-self: center;
      color: @black;

      h3 {
        margin: 0 15px 15px;
        .sans-serif-medium();
        font-size: 20px;
        line-height: 25px;
      }

      h4 {
        margin: 0 15px 15px;
        .sans-serif-regular();
        font-size: 15px;
        line-height: 20px;
        text-transform: none;
      }

      .button {
        margin: 0 15px;
        border-color: @black;
        .sans-serif-regular;
        font-size: 11px;
        color: @black;
        &:after {
          padding-left: 5px;
          .icon();
          content: '\f105';
          vertical-align: baseline;
        }
      }
    }
  }
}
@media screen and (min-width: 400px) {
  .landingpage .decorative-banner {
    .images {
      .text {
        h3 {
          margin: 0 20px 20px;
          font-size: 25px;
          line-height: 30px;
        }

        h4 {
          margin: 0 20px 20px;
          font-size: 20px;
          line-height: 25px;
        }

        .button {
          margin: 0 20px;
          font-size: 13px;
        }
      }
    }
  }
}
@media screen and (min-width: 480px) {
  .landingpage .decorative-banner {
    .images {
      .text {
        h3 {
          margin: 0 30px 30px;
          font-size: 30px;
          line-height: 35px;
        }

        h4 {
          margin: 0 30px 30px;
          font-size: 25px;
          line-height: 30px;
        }

        .button {
          margin: 0 30px;
          font-size: 13px;
        }
      }
    }
  }
}
@media screen and (min-width: 640px) {
  .landingpage .decorative-banner {
    .images {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;

      .image-4 {
        grid-column-start: 4;
        grid-column-end: 6;
        grid-row-start: 1;
        grid-row-end: 3;
      }

      .image-5 {
        grid-column-start: 6;
        grid-column-end: 7;
        grid-row-start: 1;
        grid-row-end: 2;
      }

      .image-6 {
        grid-column-start: 6;
        grid-column-end: 7;
        grid-row-start: 2;
        grid-row-end: 3;
      }

      .text {
        h3 {
          margin: 0 15px 15px;
          font-size: 20px;
          line-height: 25px;
        }

        h4 {
          margin: 0 15px 15px;
          font-size: 15px;
          line-height: 20px;
        }

        .button {
          margin: 0 15px;
          font-size: 11px;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .landingpage .decorative-banner {
    .images {
      grid-column-gap: 10px;
      grid-row-gap: 10px;

      .text {
        h3 {
          margin: 0 15px 20px;
          font-size: 25px;
          line-height: 30px;
        }

        h4 {
          margin: 0 15px 20px;
          font-size: 20px;
          line-height: 25px;
        }

        .button {
          margin: 0 15px;
          font-size: 13px;
        }
      }
    }
  }
}
@media screen and (min-width: 960px) {
  .landingpage .decorative-banner {
    .images {
      .text {
        h3 {
          margin: 0 20px 30px;
          font-size: 30px;
          line-height: 35px;
        }

        h4 {
          margin: 0 20px 30px;
          font-size: 25px;
          line-height: 30px;
        }

        .button {
          margin: 0 20px;
          font-size: 15px;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .landingpage .decorative-banner {
    .images {
      .text {
        h3 {
          font-size: 35px;
          line-height: 45px;
        }

        h4 {
          font-size: 30px;
          line-height: 35px;
        }
      }
    }
  }
}


// gold alloys
.landingpage .gold-alloys-banner {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  h2 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  picture {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .buttons {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    a {
      display: grid;
      grid-template-columns: auto max-content;
      grid-template-rows: 20px auto;
      grid-row-gap: 10px;
      align-items: center;
      box-sizing: border-box;
      padding: 10px;
      border: 1px solid @mediumgrey;
      border-radius: 5px;
      color: @darkgrey;
      text-decoration: none;

      span {
        display: block;

        &.title {
          grid-column-start: 1;
          grid-column-end: 2;
          .sans-serif-medium();
        }

        &.details {
          grid-column-start: 1;
          grid-column-end: 2;
          display: grid;
          grid-template-columns: max-content auto;
          grid-template-rows: 1fr;
          grid-column-gap: 10px;
          align-items: center;
          .sans-serif-regular();
          font-size: 12px;
          line-height: 15px;

          .graphic {
            position: relative;
            width: 40px;
            height: 40px;
            border-radius: 100%;

            &:after {
              position: absolute;
              top: 0;
              left: 0;
              width: 40px;
              height: 40px;
              content: '';
              border-radius: 100%;
              background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(224, 224, 224, 1) 50%, rgba(224, 224, 224, 1) 100%);
            }

            &.gold9k {
              background: linear-gradient(225deg, rgba(236, 188, 62, 1) 0%, rgba(236, 188, 62, 1) 50%, rgba(224, 224, 224, 1) 50%, rgba(224, 224, 224, 1) 100%);
            }

            &.gold10k {
              background: linear-gradient(239.76deg, rgba(236, 188, 62, 1) 0%, rgba(236, 188, 62, 1) 50%, rgba(224, 224, 224, 1) 50%, rgba(224, 224, 224, 1) 100%);
            }

            &.gold14k {
              background: linear-gradient(300.6deg, rgba(236, 188, 62, 1) 0%, rgba(236, 188, 62, 1) 50%, rgba(224, 224, 224, 1) 50%, rgba(224, 224, 224, 1) 100%);

              &:after {
                background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(236, 188, 62, 1) 50%, rgba(236, 188, 62, 1) 100%);
              }
            }

            &.gold18k {
              background: linear-gradient(0deg, rgba(236, 188, 62, 1) 0%, rgba(236, 188, 62, 1) 50%, rgba(224, 224, 224, 1) 50%, rgba(224, 224, 224, 1) 100%);

              &:after {
                background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(236, 188, 62, 1) 50%, rgba(236, 188, 62, 1) 100%);
              }
            }
          }

          .text {
            text-align: left;
          }
        }

        &.cta {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 3;
          .sans-serif-regular();
          color: @pink;
          text-align: right;

          &:after {
            padding-left: 5px;
            font-family: @icon;
            content: '\f105';
            vertical-align: text-top;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 400px) {
  .landingpage .gold-alloys-banner {
    .buttons {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;

      a {
        grid-template-columns: 1fr;
        grid-template-rows: 20px auto 20px;
        align-items: center;

        span {
          &.title {
          }

          &.details {
          }

          &.cta {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 3;
            grid-row-end: 4;
            text-align: left;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 480px) {
  .landingpage .gold-alloys-banner {
    .buttons {
      a {
        span {
          &.details {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .landingpage .gold-alloys-banner {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;

    h2 {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    picture {
      grid-row-start: 2;
      grid-row-end: 3;
    }

    .buttons {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;

      a {
        padding: 5px;
        grid-row-gap: 5px;

        span {
          &.details {
            .graphic {
              width: 30px;
              height: 30px;

              &:after {
                width: 30px;
                height: 30px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 820px) {
  .landingpage .gold-alloys-banner {
    .buttons {
      a {
        padding: 5px 10px;

        span {
          &.details {
            .graphic {
              width: 40px;
              height: 40px;

              &:after {
                width: 40px;
                height: 40px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .landingpage .gold-alloys-banner {
    .buttons {
      a {
        padding: 10px;
        grid-row-gap: 10px;

        span {
          &.title {
            font-size: 15px;
          }

          &.details {
            font-size: 13px;
            line-height: 18px;

            .graphic {
              width: 50px;
              height: 50px;

              &:after {
                width: 50px;
                height: 50px;
              }
            }
          }

          &.cta {
            font-size: 15px;
          }
        }
      }
    }
  }
}