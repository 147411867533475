.jewelry-finder {
  display: grid;
  grid-template-columns: 1fr;
  width: calc(100% - 10px);
  overflow: hidden;
  margin: 0 5px;
  text-align: center;
  &.silver,
  &.gold {
    background: none;
  }
  .step {
    position: relative;
    z-index: 1;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 30px 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    background-color: @white;
    transition: left .5s;
    h2 {
      grid-column-start: 1;
      grid-column-end: 3;
      margin: 0;
      font-size: 15px;
      line-height: 20px;
    }
    & > div {
      position: relative;
      cursor: pointer;
      overflow: hidden;
      & > span:not(.flex-text) {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 3;
        width: 100%;
        background-color: @juwelogold;
        .sans-serif-medium();
        font-size: 15px;
        line-height: 32px;
        text-transform: uppercase;
        color: @white;
      }
      & > .flex-text {
        position: absolute;
        top: 12%;
        left: 21.5%;
        right: 21.5%;
        bottom: 31%;
        z-index: 2;
        text-align: center;
        letter-spacing: 0.02em;
        .sans-serif-bold();
        &:after {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 100%;
          content: '';
          opacity: .05;
          background-image: url('../images/circle-fade.svg');
        }
        .prefix {
          position: absolute;
          left: 0;
          width: 100%;
          bottom: 56%;
          font-size: clamp(13px, 4vw, 26px);
          line-height: 1em;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          background-size: 100%;
        }
        .price {
          position: absolute;
          left: 0;
          width: 100%;
          top: 44%;
          font-size: clamp(25px, 8vw, 50px);
          line-height: 1em;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          background-size: 100%;
        }
      }
    }
  }
  .step-1,
  .step-3 {
    &[data-material="gg"] .ss,
    &[data-material="gg"] .wg,
    &[data-material="gg"] .rg {
      display: none;
    }
    &[data-material="wg"] .gg,
    &[data-material="wg"] .rg {
      display: none;
    }
    &[data-material="rg"] .gg,
    &[data-material="rg"] .wg {
      display: none;
    }
    &[data-material="gs"] .ss,
    &[data-material="gs"] .rs {
      display: none;
    }
    &[data-material="rs"] .gs,
    &[data-material="rs"] .ss {
      display: none;
    }
    &[data-material="ss"] .gg,
    &[data-material="ss"] .gs,
    &[data-material="ss"] .rs {
      display: none;
    }

    &[data-material="gg"],
    &[data-material="gs"] {
      & > div {
        & > .flex-text {
          background-image: url('../images/circle-gold.svg');
          .prefix {
            color: @juwelogold;
            background-image: url('../images/background-gold.svg');
          }
          .price {
            color: @juwelogold;
            background-image: url('../images/background-gold.svg');
          }
        }
      }
    }
    &[data-material="ss"],
    &[data-material="wg"] {
      & > div {
        & > .flex-text {
          background-image: url('../images/circle-silver.svg');
          .prefix {
            color: @titanium;
            background-image: url('../images/background-silver.svg');
          }
          .price {
            color: @titanium;
            background-image: url('../images/background-silver.svg');
          }
        }
      }
    }

    &[data-material="rs"],
    &[data-material="rg"] {
      & > div {
        & > .flex-text {
          background-image: url('../images/circle-rosegold.svg');
          .prefix {
            color: @rosegold;
            background-image: url('../images/background-rosegold.svg');
          }
          .price {
            color: @rosegold;
            background-image: url('../images/background-rosegold.svg');
          }
        }
      }
    }
  }
  .step-2 {
    picture {
      display: none;
    }
    &[data-jewelry-type="t1"] picture[data-jewelry-type="t1"] {
      display: block;
    }
    &[data-jewelry-type="t2"] picture[data-jewelry-type="t2"] {
      display: block;
    }
    &[data-jewelry-type="t3"] picture[data-jewelry-type="t3"] {
      display: block;
    }
    &[data-jewelry-type="t4"] picture[data-jewelry-type="t4"] {
      display: block;
    }
  }
  .step-4 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    background-color: rgba(255,255,255,.75);
    p {
      position: absolute;
      left: 20px;
      right: 20px;
      bottom: 50%;
      margin: 0;
      .sans-serif-medium();
      font-size: 16px;
      text-align: center;
    }
  }

  &[data-step="1"] {
    .step-1 {
      z-index: 2;
      left: 0;
    }
    .step-2,
    .step-3 {
      left: 100%;
    }
  }
  &[data-step="2"] {
    .step-2 {
      z-index: 2;
      left: 0;
    }
    .step-1 {
      left: -100%;
    }
    .step-3 {
      left: 100%;
    }
  }
  &[data-step="3"] {
    .step-3 {
      z-index: 2;
      left: 0;
    }
    .step-1,
    .step-2 {
      left: -100%;
    }
  }
  &[data-step="4"] {
    .step-3 {
      z-index: 2;
    }
    .step-4 {
      z-index: 3;
    }
  }

  .progress {
    grid-row-start: 2;
    grid-row-end: 3;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px 0 0;
    li {
      position: relative;
      .sans-serif-medium();
      i {
        display: inline-block;
        width: 32px;
        height: 32px;
        border: 1px solid @juwelolightgold;
        border-radius: 100%;
        line-height: 32px;
        font-style: normal;
        color: @juwelolightgold;
      }
      .text {
        display: block;
        margin: 10px 0 0;

        color: @juwelolightgold;
      }
      .line {
        position: absolute;
        top: 16px;
        border-top: 1px solid @juwelolightgold;
      }
      &:first-child {
        i:before {
          content: '1';
        }
        .line {
          left: calc(50% + 26px);
          right: 0;
        }
      }
      &:nth-child(2) {
        i:before {
          content: '2';
        }
        .line {
          left: 0;
          right: calc(50% + 26px);
          & + .line {
            left: calc(50% + 26px);
            right: 0;
          }
        }
      }
      &:nth-child(3) {
        i:before {
          content: '3';
        }
        .line {
          left: 0;
          right: calc(50% + 26px);
          & + .line {
            display: none;
          }
        }
      }
      &:last-child {
        display: none;
        i:before {
          content: '4';
        }
      }
    }
  }

  &[data-step="1"] .progress li:first-child {
    i {
      border-color: @juwelogold;
      color: @juwelogold;
    }
    .text {
      color: @juwelogold;
    }
  }
  &[data-step="2"] .progress {
    li:first-child,
    li:nth-child(2) {
      i {
        border-color: @juwelogold;
        color: @juwelogold;
      }
      .text {
        color: @juwelogold;
      }
    }
    li:first-child {
      i {
        cursor: pointer;
        &:before {
          font-family: @icon;
          content: '\f00c';
        }
      }
      .line {
        border-color: @juwelogold;
      }
    }
    li:nth-child(2) {
      .line:first-of-type {
        border-color: @juwelogold;
      }
    }
  }
  &[data-step="3"] .progress {
    li:first-child,
    li:nth-child(2),
    li:nth-child(3) {
      i {
        border-color: @juwelogold;
        color: @juwelogold;
      }
      .text {
        color: @juwelogold;
      }
    }
    li:first-child,
    li:nth-child(2) {
      i {
        cursor: pointer;
        &:before {
          font-family: @icon;
          content: '\f00c';
        }
      }
      .text {
        color: @juwelogold;
      }
      .line {
        border-color: @juwelogold;
      }
    }
    li:nth-child(3) {
      .line:first-of-type {
        border-color: @juwelogold;
      }
    }
  }
  &[data-step="4"] .progress {
    li:first-child,
    li:nth-child(2),
    li:nth-child(3),
    li:last-child {
      i {
        border-color: @juwelogold;
        color: @juwelogold;
        &:before {
          font-family: @icon;
          content: '\f00c';
        }
      }
      .text {
        color: @juwelogold;
      }
      .line {
        border-color: @juwelogold;
      }
    }
  }
}
@media screen and (min-width: 640px) {
  .jewelry-finder {
    .step {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 30px 1fr;
      h2 {
        grid-column-end: 5;
        font-size: 20px;
        line-height: 25px;
      }
      & > div {
        & > .flex-text {
          .prefix {
            font-size: clamp(13px, 2vw, 26px);
          }
          .price {
            font-size: clamp(25px, 4vw, 50px);
          }
        }
      }
      &.step-2 {
        div:nth-child(2){
          grid-column-start: 2;
          grid-column-end: 3;
        }
        div:nth-child(3){
          grid-column-start: 3;
          grid-column-end: 4;
        }
      }
      &.step-4 {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        background-color: rgba(255,255,255,.75);
      }
    }

    /* step 2 grid adjustment for "mono-metall" */
    &.gold,
    &.silver {
      .step.step-2 {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        h2 {
          grid-column-start: 1;
          grid-column-end: 9;
        }
        & > div:first-of-type {
          grid-column-start: 2;
          grid-column-end: 4;
        }
        & > div:nth-of-type(2) {
          grid-column-start: 4;
          grid-column-end: 6;
        }
        & > div:nth-of-type(3) {
          grid-column-start: 6;
          grid-column-end: 8;
        }
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .jewelry-finder {
    width: calc(100% - 20px);
    margin: 0 10px;
    .step {
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }
  }
}
@media screen and (min-width: 1025px) {
  .jewelry-finder {
    .progress {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      li {
        &:first-child {}
        &:nth-child(2) {}
        &:nth-child(3) {
          .line {
            left: 0;
            right: calc(50% + 26px);
            & + .line {
              display: block;
              left: calc(50% + 26px);
              right: 0;
            }
          }
        }
        &:last-child {
          display: block;
          .line {
            left: 0;
            right: calc(50% + 26px);
            & + .line {
              display: none;
            }
          }
        }
      }
    }
  }
}
